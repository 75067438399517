import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@/lib/redux';
import { getFeatureFlag } from '@/lib/redux/slices/feature-flags/actions';
import { type Feature } from '@/types/FeatureFlag';

export function useFeatureFlag(flagId: Feature): boolean | undefined {
  const featureFlag = useAppSelector(state => state.featureFlags.feature[flagId as Feature]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (featureFlag === undefined) {
      void dispatch(getFeatureFlag({ flagId }));
    }
  }, [flagId, featureFlag, dispatch]);

  return featureFlag?.value;
}
