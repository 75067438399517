import { useState } from 'react';
import { createPortal } from 'react-dom';

import CrossIcon from '@/components/icons/CrossIcon';
import ModalSpinner from '@/components/common/spinners/ModalSpinner';
import { mediumGrey } from '@/utils/colors';

import styles from './Modal.module.scss';

export default function Modal({
  contentLoading = false,
  children,
  onClose,
}: {
  contentLoading?: boolean;
  children: React.ReactNode;
  onClose: () => void;
}): React.JSX.Element {
  const [clickStartedInside, setClickStartedInside] = useState(false);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (event.target instanceof HTMLElement && event.target.closest(`.${styles.content}`)) {
      setClickStartedInside(true);
    }
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (event.target instanceof HTMLElement && !event.target.closest(`.${styles.content}`) && !clickStartedInside) {
      onClose();
    }
    setClickStartedInside(false);
  };

  return createPortal(
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className={styles.wrapper}
    >
      <div className={styles.content}>
        {contentLoading && <ModalSpinner />}
        <button
          className={styles.closeIcon}
          onClick={() => onClose()}
        >
          <CrossIcon
            width={`${20 / 16}rem`}
            height={`${20 / 16}rem`}
            color={mediumGrey.cssColor}
            thickness='1.5'
          />
        </button>
        {children}
      </div>
    </div>,
    document.body,
  );
}
