import { useEffect, useMemo, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import Spinner from '@/components/common/spinners/Spinner';
import Table from '@/components/common/tables/Table';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { getPaymentMethodGroupTopup } from '@/lib/redux/slices/payment-method-groups-topup/actions';
import { Permissions } from '@/types/UserPermissions';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { PAYMENT_METHODS_TOPUP_NO_GROUP_ID } from '@/utils/constants';

import PaymentMethodsGroupsTableRow from './PaymentMethodsGroupsTableRow';

const PaymentMethodsGroupsTable: FC = () => {
  const { t } = useTranslation('configurations');

  const dispatch = useAppDispatch();
  const paymentMethodGroups = useAppSelector(
    ({ paymentMethodGroupTopup }) => paymentMethodGroupTopup.paymentMethodGroups,
  );
  const loading = useAppSelector(({ paymentMethodGroupTopup }) => paymentMethodGroupTopup.loading);
  const successModalCall = useAppSelector(({ paymentMethodGroupTopup }) => paymentMethodGroupTopup.modal.success);
  const countryCode = useAppSelector(({ country }) => country.country.countryCode);

  const hasEditPermission = usePermissions(Permissions.countryConfigEdit);
  const isReadOnly = !hasEditPermission;

  useEffect(() => {
    if (countryCode) {
      void dispatch(getPaymentMethodGroupTopup(countryCode));
    }
  }, [dispatch, countryCode]);

  useEffect(() => {
    if (successModalCall && countryCode) {
      void dispatch(getPaymentMethodGroupTopup(countryCode));
    }
  }, [dispatch, countryCode, successModalCall]);

  const noGroupPaymentMethods = useMemo(
    () => ({
      id: PAYMENT_METHODS_TOPUP_NO_GROUP_ID,
      icon: '-',
      nameTranslationKey: t('payment-method-topup.no-group-methods'),
      descriptionTranslationKey: '-',
      order: 0,
      countryCode: '-',
      reason: '-',
      isEnabled: false,
    }),
    [t],
  );

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Table cols={isReadOnly ? 5 : 6}>
          <Table.Head>{t('payment-method-topup.name-translation-key')}</Table.Head>
          <Table.Head>{t('payment-method-topup.id')}</Table.Head>
          <Table.Head>{t('payment-method-topup.order')}</Table.Head>
          <Table.Head>{t('payment-method-topup.reason')}</Table.Head>
          <Table.Head>{t('payment-method-topup.enabled')}</Table.Head>
          {!isReadOnly && <Table.Head>{t('payment-method-topup.actions')}</Table.Head>}
          {paymentMethodGroups.map(paymentMethodsGroup => {
            return (
              <PaymentMethodsGroupsTableRow
                key={paymentMethodsGroup.id}
                paymentMethodsGroup={paymentMethodsGroup}
                isReadOnly={isReadOnly}
              />
            );
          })}
          <PaymentMethodsGroupsTableRow
            key={noGroupPaymentMethods.id}
            paymentMethodsGroup={noGroupPaymentMethods}
            isReadOnly={isReadOnly}
            isNoGroupMethods
          />
        </Table>
      )}
    </>
  );
};

export default PaymentMethodsGroupsTable;
