import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import ChipsInput from '@/components/common/chips-input/ChipsInput';
import styles from './EnablePhoneNumberModal.module.scss';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { updateFeatureFlagConfig, UpdateType } from '@/lib/redux/slices/feature-flags/actions';
import { resetFeatureFlagConfig } from '@/lib/redux/slices/feature-flags/slice';
import { type MuiChipsInputChip } from 'mui-chips-input';
import { compareArrays } from '@/utils/helperFunctions';

interface EnablePhoneNumberModalProps {
  setIsEnablePhoneNumberModalOpen: (isOpen: boolean) => void;
  numbers: string[];
  flagId: string;
}

const EnablePhoneNumberModal: FC<EnablePhoneNumberModalProps> = props => {
  const { setIsEnablePhoneNumberModalOpen, numbers, flagId } = props;
  const { isLoadingConfig, successfulConfigAddition } = useAppSelector(state => state.featureFlags);
  const { t } = useTranslation('configurations');
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(numbers);

  const regex = /^\d{8,15}$/;

  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    setIsEnablePhoneNumberModalOpen(false);
  };

  const handleConfirm = (): void => {
    void dispatch(updateFeatureFlagConfig({ type: UpdateType.PhoneNumbers, flagId, body: { phoneNumbers } }));
  };

  useEffect(() => {
    setPhoneNumbers(numbers);
  }, [numbers]);

  const validation = (chipValue: MuiChipsInputChip): boolean => {
    const value = String(chipValue);
    return !regex.test(value);
  };

  useEffect(() => {
    if (successfulConfigAddition) {
      dispatch(resetFeatureFlagConfig());
      setIsEnablePhoneNumberModalOpen(false);
    }
  }, [dispatch, successfulConfigAddition, setIsEnablePhoneNumberModalOpen]);

  const handleChange = (newChips: string[]): void => {
    const numericChips = newChips.filter(chip => regex.test(chip));
    setPhoneNumbers(numericChips);
  };

  const disabledButton = isLoadingConfig || compareArrays(numbers, phoneNumbers);

  return (
    <ActionModal
      title={t('feature-flags.enable-phone-number.title')}
      titleVariant='p0'
      disabledButton={disabledButton}
      confirmButtonLabel={t('feature-flags.add-feature.create')}
      closeButtonLabel={t('confirm-modal.cancel', { ns: 'global' })}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      <div className={styles.chipsInputContainer}>
        <ChipsInput
          validation={validation}
          validationMessage={t('feature-flags.enable-phone-number.validation-error')}
          value={phoneNumbers}
          onChange={handleChange}
        />
      </div>
    </ActionModal>
  );
};

export default EnablePhoneNumberModal;
