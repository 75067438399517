import { useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import ChipsInput from '@/components/common/chips-input/ChipsInput';
import styles from './EnableCountriesModal.module.scss';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { updateFeatureFlagConfig, UpdateType } from '@/lib/redux/slices/feature-flags/actions';
import { resetFeatureFlagConfig } from '@/lib/redux/slices/feature-flags/slice';
import { compareArrays } from '@/utils/helperFunctions';
interface EnableCountriesModalProps {
  setIsEnableCountriesModalOpen: (isOpen: boolean) => void;
  countries: string[];
  flagId: string;
}

const EnableCountriesModal: FC<EnableCountriesModalProps> = props => {
  const { setIsEnableCountriesModalOpen, countries: countriesValue, flagId } = props;
  const { isLoadingConfig, successfulConfigAddition } = useAppSelector(state => state.featureFlags);
  const { t } = useTranslation('configurations');
  const [countries, setCountries] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    setIsEnableCountriesModalOpen(false);
  };

  const handleConfirm = (): void => {
    void dispatch(updateFeatureFlagConfig({ type: UpdateType.Countries, flagId, body: { countries } }));
  };

  useEffect(() => {
    setCountries(countriesValue);
  }, [countriesValue]);

  useEffect(() => {
    if (successfulConfigAddition) {
      dispatch(resetFeatureFlagConfig());
      setIsEnableCountriesModalOpen(false);
    }
  }, [dispatch, successfulConfigAddition, setIsEnableCountriesModalOpen]);

  const handleChange = (newChips: string[]): void => {
    setCountries(newChips);
  };

  const disabledButton = isLoadingConfig || compareArrays(countries, countriesValue);

  return (
    <ActionModal
      title={t('feature-flags.enable-countries.title')}
      titleVariant='p0'
      disabledButton={disabledButton}
      confirmButtonLabel={t('feature-flags.add-feature.create')}
      closeButtonLabel={t('confirm-modal.cancel', { ns: 'global' })}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      <div className={styles.chipsInputContainer}>
        <ChipsInput
          value={countries}
          onChange={handleChange}
        />
      </div>
    </ActionModal>
  );
};

export default EnableCountriesModal;
