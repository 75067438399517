import { createSlice } from '@reduxjs/toolkit';
import { transactionRewardReversal } from '@/lib/redux/slices/transaction-reward-reversal/actions';
interface TransactionRewardReversalState {
  error?: string;
  isLoading: boolean;
  rewardReversalSuccess: boolean;
}
const initialState: TransactionRewardReversalState = {
  rewardReversalSuccess: false,
  isLoading: false,
};
export const transactionRewardReversalSlice = createSlice({
  name: 'transactionRewardReversaSlice',
  initialState,
  reducers: {
    resetRewardReversal() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(transactionRewardReversal.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(transactionRewardReversal.fulfilled, state => {
      state.rewardReversalSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(transactionRewardReversal.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message ?? 'Failed reversal';
    });
  },
});
export const { resetRewardReversal } = transactionRewardReversalSlice.actions;
