import React from 'react';
import { MuiChipsInput, type MuiChipsInputChip } from 'mui-chips-input';
import styled from 'styled-components';
import { toRem } from 'k8-web-lib-tmp';
import { useTranslation } from 'react-i18next';

import { mediumPurple, lightWhite, white, marine, boldRed } from '@/utils/colors';

const MuiChipsInputStyled = styled(MuiChipsInput)`
  width: 100%;
  
  .MuiInputBase-root {
    width: 100%;
    border-radius: ${toRem(10)};
    background-color: ${lightWhite.cssColor};
  }

  .MuiChipsInput-Chip {
    background-color: ${white.cssColor};
    border-radius: ${toRem(1)};
    color: ${mediumPurple.cssColor};
    font-weight: 500;
    font-family: 'Moderat', sans-serif;
    font-size: ${toRem(13)};
  }

  fieldset {
    border: none;
  }

  input.MuiInputBase-input:focus ~ fieldset {
    border: ${toRem(1)} solid ${marine.cssColor};
  }

  .Mui-error {
    fieldset {
      border: ${toRem(1)} solid ${boldRed.cssColor} !important;
    }
  }
`;

export interface ChipsInputProps {
  value: string[];
  size?: 'small' | 'medium';
  /**
   * May be provided without the validation prop. Colors the border of the input in error theme when value is true.
   */
  error?: boolean;
  disabled?: boolean;
  /**
   * Needs a validationMessage to render under the component in error color and colors the input border.
   * @param {string} chipValue - String value provided by the vendor component
   * @returns - If the validation leads to error. Assigning the returned value to the ```isError``` prop so true means has error.
   */
  validation?: (chipValue: MuiChipsInputChip) => boolean;
  /**
   * Has to be provided when a validation function has been passed. Renders a message in the error color under the input.
   * If a message is not provided does not render the error state in the UI with the colors, but still validates.
   */
  validationMessage?: string;
  /**
   * Renders a text below the input
   */
  helperText?: string;
  placeholderText?: string;
  onChange?: (newChips: string[]) => void;
}

function ChipsInput({
  value,
  size = 'small',
  error = false,
  disabled = false,
  validation,
  validationMessage,
  helperText,
  placeholderText,
  onChange,
}: Readonly<ChipsInputProps>): React.JSX.Element {
  const { t } = useTranslation(['global']);

  const validate = (chipValue: MuiChipsInputChip): boolean => {
    if (validation) {
      return validation(chipValue);
    }
    return false;
  };

  const handlePaste = (e: React.ClipboardEvent): void => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const newChips = pastedText.split(/[\s,]+/).filter(Boolean);
    if (newChips.length && onChange) {
      onChange([...value, ...newChips]);
    }
  };

  return (
    <MuiChipsInputStyled
      value={value}
      onChange={onChange}
      size={size}
      clearInputOnBlur
      onPasteCapture={handlePaste}
      validate={chipValue => ({
        isError: validate(chipValue),
        textError: validationMessage || '',
      })}
      helperText={helperText}
      error={error}
      disabled={disabled}
      placeholder={placeholderText || t('chips.placeholder')}
    />
  );
}

export default ChipsInput;
