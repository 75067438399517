import { type FC, type PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { type Feature } from '@/types/FeatureFlag';
import { AppUrls } from '@/utils/constants';
import { useFeatureFlag } from '@/utils/hooks/useFeatureFlag';
import Spinner from '@/components/common/spinners/Spinner';

type FeatureFlagProps = Readonly<
  PropsWithChildren<{
    featureFlag: Feature;
    redirect?: string | boolean;
  }>
>;

const FeatureFlag: FC<FeatureFlagProps> = ({ children, featureFlag, redirect }) => {
  const isEnabled = useFeatureFlag(featureFlag);
  const isPageLoading = isEnabled === undefined;
  const isRedirect = redirect !== undefined;

  if (isPageLoading && isRedirect) {
    return <Spinner />;
  }

  if (isPageLoading) {
    return null;
  }

  if (isEnabled) {
    return <>{children}</>;
  }

  if (isRedirect) {
    return <Navigate to={typeof redirect === 'string' ? redirect : AppUrls.NotFoundUrl} />;
  }

  return null;
};

export default FeatureFlag;
