import { useState, useEffect, type FC } from 'react';

import Input from '@/components/common/inputs/Input';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { createLimit, updateLimit } from '@/lib/redux/slices/limits/actions';
import { Permissions } from '@/types';
import {
  MoneyFlowTypeEnum,
  LimitTypeEnum,
  PeriodTypeEnum,
  PeriodTypeStoreKeysEnum,
  type CreateLimitBody,
  type LimitsByPeriod,
  type MoneyOutSplitEmum,
} from '@/types/Limits';
import { parseNumberOrDefault } from '@/utils/helperFunctions';
import { usePermissions } from '@/utils/hooks/usePermissions';

import { GridItem, GridContainer, RowTitle } from './styles';

const columns = Object.values(PeriodTypeEnum).filter(key => key !== PeriodTypeEnum.Annual);

type AccordionContentRowProps = Readonly<{
  title: string;
  data: LimitsByPeriod;
  limitsSplit: MoneyOutSplitEmum;
  channelId: string;
}>;

const AccordionContentRow: FC<AccordionContentRowProps> = ({ title, data, limitsSplit, channelId }) => {
  const dispatch = useAppDispatch();
  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);

  const countryCode = useAppSelector(state => state.country.country.countryCode);
  const errorFieldUpdate = useAppSelector(state => state.limits.errorFieldUpdate);
  const limitLevel = useAppSelector(state => state.limits.selectedLevel?.text ?? '');

  const [localData, setLocalData] = useState(data);
  const [inputTouched, setInputTouched] = useState(false);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    // This is to return back the flag so that blur can be fired, without user to change the input
    if (errorFieldUpdate) {
      setInputTouched(true);
    }
  }, [errorFieldUpdate]);

  const handleOnChangeFactory = (periodType: PeriodTypeEnum) => (value: string) => {
    setLocalData(prevLimits => {
      const storeKey = PeriodTypeStoreKeysEnum[periodType];
      const parsedValue = parseNumberOrDefault(value, 0);

      if (prevLimits[storeKey]?.value === parsedValue) {
        return prevLimits;
      }

      setInputTouched(true);
      return {
        ...prevLimits,
        [storeKey]: {
          ...prevLimits[storeKey],
          value: parsedValue,
        },
      };
    });
  };

  const handleInputBlurFactory = (periodType: PeriodTypeEnum) => () => {
    const storeKey = PeriodTypeStoreKeysEnum[periodType];
    const limitDatum = localData[storeKey];

    if (!inputTouched || !limitDatum) {
      return;
    }

    // if (limitDatum && limitDatum.value >= 0) {
    if (limitDatum.id === undefined) {
      const requestBodyData: CreateLimitBody = {
        limitConfigurations: [
          {
            channelId,
            limitLevel,
            periodType,
            value: Number(limitDatum.value),
            limitType: LimitTypeEnum.Amount,
            moneyFlowType: MoneyFlowTypeEnum.MoneyOut,
            countryCode,
          },
        ],
      };

      void dispatch(createLimit({ data: requestBodyData, limitsSplit }));
    } else {
      void dispatch(
        updateLimit({
          data: { value: Number(limitDatum?.value) },
          limitId: limitDatum.id,
          period: limitDatum.periodType,
          limitsSplit,
          moneyFlowType: MoneyFlowTypeEnum.MoneyOut,
        }),
      );
    }
    // }

    setInputTouched(false);
  };

  return (
    <GridContainer>
      <div className='inner-container'>
        <GridItem $cell={[1, 2]}>
          <RowTitle>{title}</RowTitle>
        </GridItem>
        {columns.map((key, idx) => {
          const storeKey = PeriodTypeStoreKeysEnum[key];
          const value = localData[storeKey]?.value?.toString() || '';
          const handleOnChange = handleOnChangeFactory(key);
          const handleInputBlur = handleInputBlurFactory(key);

          return (
            <GridItem
              key={key}
              $cell={[idx + 3, 1]}
            >
              <Input
                value={value}
                onChange={handleOnChange}
                onBlur={handleInputBlur}
                disabled={!hasCountryEditPermission}
              />
            </GridItem>
          );
        })}
      </div>
    </GridContainer>
  );
};

export default AccordionContentRow;
