import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, type RootState, useAppDispatch } from '@/lib/redux';
import { useTranslation } from 'react-i18next';
import Layout from '@/components/layout/Layout';
import ContentBox from '@/components/common/content-box/ContentBox';
import styles from './HistoryLogsPage.module.scss';
import Table from '@/components/common/tables/Table';

import Spinner from '@/components/common/spinners/Spinner';

import { getFeatureFlagHistory } from '@/lib/redux/slices/feature-flag-history/actions';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';

const HistoryLogsPage = (): JSX.Element => {
  const { t } = useTranslation('configurations');
  const { id } = useParams<{ id: string }>();
  useDocumentTitle(t('history-logs-page.overview-page-title', { name: id }));
  const dispatch = useAppDispatch();
  const { history, isLoading } = useAppSelector((state: RootState) => state.featureFlagHistory);
  const headers = [
    { name: t('history-logs-page.table-headers.timestamp'), label: 'timestamp' },
    { name: t('history-logs-page.table-headers.user'), label: 'user' },
    { name: t('history-logs-page.table-headers.type'), label: 'type' },
    { name: t('history-logs-page.table-headers.old-value'), label: 'oldValue' },
    { name: t('history-logs-page.table-headers.new-value'), label: 'newValue' },
  ];

  useEffect(() => {
    if (id !== undefined) {
      void dispatch(getFeatureFlagHistory({ flagId: id }));
    }
  }, [id, dispatch]);

  return (
    <Layout>
      <div className={styles.main}>
        <div className={styles.header}>
          <h1>{t('history-logs-page.feature-history')}</h1>
        </div>
        <ContentBox>
          <div className={styles['table-wrapper']}>
            {isLoading && <Spinner />}
            <Table cols={5}>
              {headers.map(header => (
                <Table.Head key={header.label}>{header.name}</Table.Head>
              ))}
              {!isLoading &&
                history.map(log => (
                  <Table.Row key={log.featureFlagId}>
                    <Table.Cell>{''}</Table.Cell>
                    <Table.Cell>{log?.username}</Table.Cell>
                    <Table.Cell>{log?.eventType}</Table.Cell>
                    <Table.Cell>{''}</Table.Cell>
                    <Table.Cell>{''}</Table.Cell>
                  </Table.Row>
                ))}
            </Table>
            {!isLoading && (
              <div className={styles.emptyCard}>{history.length > 0 ? '' : t('history-logs-page.no-results')}</div>
            )}
          </div>
        </ContentBox>
      </div>
    </Layout>
  );
};

export default HistoryLogsPage;
