import { useEffect, useCallback, useMemo, useRef, type FC } from 'react';

import { Checkbox } from 'k8-web-lib-tmp';
import { useTranslation } from 'react-i18next';

import Button from '@/components/common/button/Button';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import Modal from '@/components/common/modals/Modal';
import Typography from '@/components/common/typography/Typography';
import Dropdown from '@/components/common/filters/Dropdown';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { createPaymentMethodTopup, updatePaymentMethodTopup } from '@/lib/redux/slices/payment-methods-topup/actions';
import { setModalData, closeAndResetModal } from '@/lib/redux/slices/payment-methods-topup/slice';
import { type PaymentMethod } from '@/types/PaymentMethod';
import { parseNumberOrDefault } from '@/utils/helperFunctions';
import { type Option } from '@/utils/interfaces';

import ChannelSelect from './ChannelSelect';
import styles from './PaymentMethodTopupModal.module.scss';
import GroupSelect from './GroupSelect';

type FormValues = PaymentMethod[keyof PaymentMethod];

const identityNumberSourceOptions: Option[] = [
  {
    text: 'FiscalNumber',
    value: 'FiscalNumber',
  },
  {
    text: 'IdentityDoc',
    value: 'IdentityDoc',
  },
];

const PaymentMethodTopupModal: FC = () => {
  const { t } = useTranslation('configurations');
  const editedMethodCacheRef = useRef<PaymentMethod>();

  const dispatch = useAppDispatch();
  const { mode, loading, data: paymentMethod } = useAppSelector(({ paymentMethodTopup }) => paymentMethodTopup.modal);

  useEffect(() => {
    return () => {
      dispatch(closeAndResetModal({ modalKey: 'modal' }));
    };
  }, [dispatch]);

  const onClose = useCallback((): void => {
    dispatch(closeAndResetModal({ modalKey: 'modal' }));
  }, [dispatch]);

  const onSubmit = useCallback((): void => {
    if (mode === 'opened-create') {
      void dispatch(createPaymentMethodTopup(paymentMethod));
      return;
    }

    void dispatch(updatePaymentMethodTopup(paymentMethod));
  }, [dispatch, paymentMethod, mode]);

  const setPaymentMethod = useCallback(
    (method: PaymentMethod): void => {
      dispatch(setModalData({ modalKey: 'modal', data: method }));
    },
    [dispatch],
  );

  const isFormValid = useMemo(() => {
    const isValueValid = (value: FormValues): boolean => {
      switch (typeof value) {
        case 'undefined':
          return false;
        case 'string':
          return value.length > 0;
        case 'number':
          return value > 0;
        default:
          return true;
      }
    };

    if (mode === 'opened-create') {
      return Object.entries(paymentMethod).every(([key, value]) => {
        // Group ID is not mandatory field
        if (key === 'groupId') {
          return true;
        }

        return isValueValid(value as FormValues);
      });
    }

    if (mode === 'opened-edit') {
      // First data load, cache it for change checks
      if (editedMethodCacheRef.current === undefined) {
        editedMethodCacheRef.current = paymentMethod;
        return false;
      }

      const hasValidChange = Object.entries(paymentMethod).some(([key, value]) => {
        if (!editedMethodCacheRef.current) {
          return false;
        }

        const castedValue = value as FormValues;
        const cacheValue = editedMethodCacheRef.current[key as keyof PaymentMethod];

        // Early return if values are identical
        if (castedValue === cacheValue) {
          return false;
        }

        if (key === 'groupId') {
          return true;
        }

        // Handle string values
        if (typeof castedValue === 'string') {
          const trimmedValue = castedValue.trim();
          return trimmedValue !== cacheValue && isValueValid(trimmedValue);
        }

        // Handle non-string values
        return castedValue !== cacheValue && isValueValid(castedValue);
      });

      return hasValidChange;
    }

    // Clear change checks cached data
    editedMethodCacheRef.current = undefined;
    return false;
  }, [paymentMethod, mode]);

  if (mode === 'closed') {
    return null;
  }

  const modalTitleKey = mode === 'opened-edit' ? 'update-title' : 'create-title';
  const submitButtonKey = mode === 'opened-edit' ? 'update-button' : 'create-button';
  // Fields currently not edtiable: name, minAmountCurrency, nameTranslationKey, countryCode, channelId
  const notEditable = mode === 'opened-edit';

  return (
    <Modal onClose={onClose}>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t(`payment-method-topup.${modalTitleKey}`)}</Typography>
      </div>
      <div className={styles.container}>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.name')}>
            <Input
              value={paymentMethod.name}
              onChange={value => setPaymentMethod({ ...paymentMethod, name: value })}
              disabled={notEditable}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.group-name')}>
            <GroupSelect
              value={paymentMethod.groupId}
              onChange={value => setPaymentMethod({ ...paymentMethod, groupId: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.identityNumberSource')}>
            <Dropdown
              options={identityNumberSourceOptions}
              onSelect={(value: Option) =>
                setPaymentMethod({ ...paymentMethod, identityNumberSource: value.value ?? '' })
              }
              selectedOption={{
                value: paymentMethod.identityNumberSource,
                text: paymentMethod.identityNumberSource,
              }}
              setSelectedOption={() => {}}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.image-url')}>
            <Input
              value={paymentMethod.imageUrl}
              onChange={value => setPaymentMethod({ ...paymentMethod, imageUrl: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.order')}>
            <Input
              value={String(paymentMethod.order)}
              onChange={value =>
                setPaymentMethod({ ...paymentMethod, order: parseNumberOrDefault(value, paymentMethod.order) })
              }
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.min-amount-currency')}>
            <Input
              value={paymentMethod.minAmountCurrency}
              onChange={value => setPaymentMethod({ ...paymentMethod, minAmountCurrency: value })}
              disabled={notEditable}
            />
          </InputLabel>
          <Checkbox
            checked={paymentMethod.allowInCheckout}
            labelPosition={'left'}
            onChange={(value: boolean) => setPaymentMethod({ ...paymentMethod, allowInCheckout: value })}
          >
            <span className={styles.checkboxLabel}>{t('payment-method-topup.allowed-in-checkout')}</span>
          </Checkbox>
        </div>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.name-translation-key')}>
            <Input
              value={paymentMethod.nameTranslationKey}
              onChange={value => setPaymentMethod({ ...paymentMethod, nameTranslationKey: value })}
              disabled={notEditable}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.country-code')}>
            <Input
              value={paymentMethod.countryCode}
              disabled
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.channel-name')}>
            <ChannelSelect
              onChange={value => setPaymentMethod({ ...paymentMethod, channelId: value })}
              disabled={notEditable}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.description-translation-key')}>
            <Input
              value={paymentMethod.descriptionTranslationKey}
              onChange={value => setPaymentMethod({ ...paymentMethod, descriptionTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.min-amount')}>
            <Input
              value={String(paymentMethod.minAmount)}
              onChange={value =>
                setPaymentMethod({ ...paymentMethod, minAmount: parseNumberOrDefault(value, paymentMethod.minAmount) })
              }
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.reason')}>
            <Input
              value={paymentMethod.reason}
              onChange={value => setPaymentMethod({ ...paymentMethod, reason: value })}
            />
          </InputLabel>
          <Checkbox
            checked={paymentMethod.isEnabled}
            labelPosition={'left'}
            onChange={(value: boolean) => setPaymentMethod({ ...paymentMethod, isEnabled: value })}
          >
            <span className={styles.checkboxLabel}>{t('payment-method-topup.is-enabled')}</span>
          </Checkbox>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          disabled={!isFormValid || loading}
          textTransform='uppercase'
          onClick={onSubmit}
          data-testid={submitButtonKey}
        >
          {t(`payment-method-topup.${submitButtonKey}`)}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentMethodTopupModal;
