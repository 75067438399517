import { type CardChannelEnum } from '@/types/Channel';
import { PeriodTypeEnum, type LimitDatum, type LimitsByPeriod, type MoneyFlowTypeEnum } from '@/types/Limits';
import { type TransactionType as TransactionTypeEnum } from '@/types/Transaction';

export const groupLimitsByPeriodType = (limits: LimitDatum[]): LimitsByPeriod => {
  const limitsByPeriodBase: LimitsByPeriod = {
    byTransaction: undefined,
    daily: undefined,
    weekly: undefined,
    monthly: undefined,
    annualy: undefined,
    lifetime: undefined,
  };

  return limits.reduce<LimitsByPeriod>((accumLimits, limit) => {
    switch (limit.periodType) {
      case PeriodTypeEnum.ByTransaction:
        return { ...accumLimits, byTransaction: limit };
      case PeriodTypeEnum.Daily:
        return { ...accumLimits, daily: limit };
      case PeriodTypeEnum.Weekly:
        return { ...accumLimits, weekly: limit };
      case PeriodTypeEnum.Monthly:
        return { ...accumLimits, monthly: limit };
      case PeriodTypeEnum.Annual:
        return { ...accumLimits, annualy: limit };
      case PeriodTypeEnum.Lifetime:
        return { ...accumLimits, lifetime: limit };
      default:
        return accumLimits;
    }
  }, limitsByPeriodBase);
};

type GetLimitsPerSplitFn = (
  limits: LimitDatum[],
  options: {
    limitMoneyFlowType: MoneyFlowTypeEnum;
    channelTransactionType: TransactionTypeEnum;
    channelName?: CardChannelEnum;
  },
) => LimitDatum[];

export const getLimitsPerSplit: GetLimitsPerSplitFn = (
  limits,
  { limitMoneyFlowType, channelTransactionType, channelName },
): LimitDatum[] => {
  return limits.filter(({ channel, moneyFlowType }) => {
    if (!channel) return false;

    const isMatchingMoneyFlow = limitMoneyFlowType === moneyFlowType;
    const isMatchingTransactionType = channel.transactionType === channelTransactionType;
    const isMatchingChannelName = channelName ? channel.name === channelName : true;

    return isMatchingMoneyFlow && isMatchingTransactionType && isMatchingChannelName;
  });
};
