import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

import PaymentMethodsTable from '@/components/configurations/payment-method-topup/PaymentMethodsTable';
import PaymentMethodTopupModal from '@/components/configurations/payment-method-topup/PaymentMethodTopupModal';
import PaymentMethodTopupRelateModal from '@/components/configurations/payment-method-topup/PaymentMethodTopupRelateModal';
import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import { Permissions } from '@/types';

const PaymentMethodsTopUp = (): JSX.Element => {
  return (
    <>
      <div className={styles.emptyCard}>
        <UserPermissionsGuard permission={Permissions.countryConfigRead}>
          <PaymentMethodsTable />
        </UserPermissionsGuard>
      </div>
      <PaymentMethodTopupModal />
      <PaymentMethodTopupRelateModal />
    </>
  );
};

export default PaymentMethodsTopUp;
