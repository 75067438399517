import { useState, useEffect, useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '@/components/common/accordion/Accordion';
import { useAppSelector, useAppDispatch } from '@/lib/redux';
import { searchChannels } from '@/lib/redux/slices/limits/actions';
import { type MoneyOutSplitEmum } from '@/types/Limits';
import { TransactionType as TransactionTypeEnum } from '@/types/Transaction';

import AccordionTitle from './AccordionTitle';
import AccordionContentHeader from './AccordionContentHeader';
import AccordionContentRow from './AccordionContentRow';

const titleMap: Record<MoneyOutSplitEmum, string> = {
  paymentFromCustomer: 'Payment from customer',
  peerToPeerTransfer: 'Peer to peer transfer',
  cardATMWithdrawal: 'Prepaid ATM withdrawal',
  cardPOSSpent: 'Prepaid POS spend',
  cardVirtualSpent: 'Prepaid Virtual spend',
};

const MoneyOut: FC = () => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();

  const limits = useAppSelector(state => state.limits.moneyOutLimits);
  const limitChannels = useAppSelector(state => state.limits.moneyOutLimitChannels);
  const [active, setActive] = useState(false);

  useEffect(() => {
    void dispatch(searchChannels({ transactionType: TransactionTypeEnum.PaymentFromCustomer }));
    void dispatch(searchChannels({ transactionType: TransactionTypeEnum.Card }));
    // TransactionTypeEnum.Transfer is already loaded in
    // src/components/configurations/limits/PeerToPeerTransfer.tsx
    // TODO: Implement loading of Channels with one call and split data in relevant store field
  }, [dispatch]);

  const handleClick = useCallback(() => {
    setActive(prev => !prev);
  }, []);

  return (
    <Accordion
      title={
        <AccordionTitle
          title={t('limits.money-out-title')}
          isValid
        />
      }
      content={
        <>
          <AccordionContentHeader />
          {Object.entries(limits).map(([key, value]) => {
            const limitKey = key as MoneyOutSplitEmum;
            const channelId = limitChannels[`${limitKey}Channels`][0]?.id || '';

            return (
              <AccordionContentRow
                key={key}
                title={titleMap[limitKey]}
                data={value}
                limitsSplit={limitKey}
                channelId={channelId}
              />
            );
          })}
        </>
      }
      isActive={active}
      onClick={handleClick}
    />
  );
};

export default MoneyOut;
