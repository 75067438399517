import { useCallback, useEffect, useMemo, useRef, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'k8-web-lib-tmp';

import Button from '@/components/common/button/Button';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import Modal from '@/components/common/modals/Modal';
import Typography from '@/components/common/typography/Typography';
import { useAppDispatch, useAppSelector } from '@/lib/redux';

import styles from './PaymentMethodTopupRelateModal.module.scss';
import { closeAndResetModal, setModalData } from '@/lib/redux/slices/payment-methods-topup/slice';
import { type PaymentMethodRelation } from '@/types/PaymentMethod';
import {
  createPaymentMethodRelation,
  updatePaymentMethodRelation,
} from '@/lib/redux/slices/payment-methods-topup/actions';

type FormValues = PaymentMethodRelation[keyof PaymentMethodRelation];

const PaymentMethodTopupRelateModal: FC = () => {
  const { t } = useTranslation('configurations');
  const editedRelationCacheRef = useRef<PaymentMethodRelation>();
  const dispatch = useAppDispatch();

  const {
    mode,
    data: methodRelation,
    loading,
  } = useAppSelector(({ paymentMethodTopup }) => paymentMethodTopup.relationModal);

  useEffect(() => {
    return () => {
      dispatch(closeAndResetModal({ modalKey: 'relationModal' }));
    };
  }, [dispatch]);

  const onClose = useCallback((): void => {
    if (!loading) {
      dispatch(closeAndResetModal({ modalKey: 'relationModal' }));
    }
  }, [loading, dispatch]);

  const onSubmit = useCallback((): void => {
    if (mode === 'opened-create') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(createPaymentMethodRelation(methodRelation));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(updatePaymentMethodRelation(methodRelation));
  }, [dispatch, methodRelation, mode]);

  const setRelation = useCallback(
    (method: PaymentMethodRelation): void => {
      dispatch(setModalData({ modalKey: 'relationModal', data: method }));
    },
    [dispatch],
  );

  const isFormValid = useMemo(() => {
    const isValueValid = (value: FormValues): boolean => {
      switch (typeof value) {
        case 'undefined':
          return false;
        case 'string':
          return value.length > 0;
        case 'number':
          return value > 0;
        default:
          return true;
      }
    };

    if (mode === 'opened-create') {
      return Object.entries(methodRelation).every(([key, value]) => {
        // Not mandatory fields
        if (key === 'channel' || key === 'name' || key === 'creatorEmail' || key === 'walletAddress') {
          return true;
        }

        return isValueValid(value as FormValues);
      });
    }

    if (mode === 'opened-edit') {
      // First data load, cache it for change checks
      if (editedRelationCacheRef.current === undefined) {
        editedRelationCacheRef.current = methodRelation;
        return false;
      }

      const hasValidChange = Object.entries(methodRelation).some(([key, value]) => {
        if (!editedRelationCacheRef.current) {
          return false;
        }

        const castedValue = value as FormValues;
        const cacheValue = editedRelationCacheRef.current[key as keyof PaymentMethodRelation];

        // Early return if values are identical
        if (castedValue === cacheValue) {
          return false;
        }

        // Handle string values
        if (typeof castedValue === 'string') {
          const trimmedValue = castedValue.trim();
          return trimmedValue !== cacheValue && isValueValid(trimmedValue);
        }

        // Handle non-string values
        return castedValue !== cacheValue && isValueValid(castedValue);
      });

      return hasValidChange;
    }

    // Clear change checks cached data
    editedRelationCacheRef.current = undefined;
    return false;
  }, [mode, methodRelation]);

  if (mode === 'closed') {
    return null;
  }

  const modalTitleKey = mode === 'opened-edit' ? 'update-relation-title' : 'relate-title';
  const submitButtonKey = mode === 'opened-edit' ? 'update-button' : 'relate-button';

  return (
    <Modal
      onClose={onClose}
      contentLoading={loading}
    >
      <div className={styles.title}>
        <Typography variant='p0Bold'>
          {t(`payment-method-topup.${modalTitleKey}`, { name: methodRelation.name })}
        </Typography>
      </div>
      <div className={styles.container}>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.kuady-payment-method-id')}>
            <Input
              value={methodRelation.kuadyPaymentMethodId ?? ''}
              disabled
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.reason')}>
            <Input
              value={methodRelation.reason}
              onChange={value => setRelation({ ...methodRelation, reason: value })}
            />
          </InputLabel>
        </div>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.psp-payment-method-id')}>
            <Input
              value={methodRelation.pspPaymentMethodId}
              onChange={value => setRelation({ ...methodRelation, pspPaymentMethodId: value })}
            />
          </InputLabel>
          <div className={styles.checkboxContainer}>
            <Checkbox
              checked={methodRelation.webviewNotAllowed}
              labelPosition={'left'}
              onChange={(value: boolean) => setRelation({ ...methodRelation, webviewNotAllowed: value })}
            >
              <span className={styles.checkboxLabel}>{t('payment-method-topup.webview-not-allowed')}</span>
            </Checkbox>
          </div>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          disabled={!isFormValid || loading}
          textTransform='uppercase'
          onClick={onSubmit}
          data-testid={submitButtonKey}
        >
          {t(`payment-method-topup.${submitButtonKey}`)}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentMethodTopupRelateModal;
