import { useEffect, useCallback, type FC, useState, useMemo } from 'react';
import { Select } from 'k8-web-lib-tmp';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { getPaymentMethodGroupTopup } from '@/lib/redux/slices/payment-method-groups-topup/actions';
import { type Option } from '@/utils/interfaces';

interface GroupSelectProps {
  value?: string;
  onChange: (value?: string) => void;
}

const NO_GROUP_OPTION: Option = {
  text: 'No group',
};

export const GroupSelect: FC<GroupSelectProps> = ({ value, onChange }) => {
  const dispatch = useAppDispatch();
  const [hasFetched, setHasFetched] = useState(false);
  const { loading, paymentMethodGroups, success } = useAppSelector(state => state.paymentMethodGroupTopup);
  const { country } = useAppSelector(({ country }) => country);

  const options: Option[] = useMemo(() => {
    return paymentMethodGroups.reduce(
      (acc, group) => {
        acc.push({
          value: group.id,
          text: group.nameTranslationKey,
        });
        return acc;
      },
      [NO_GROUP_OPTION],
    );
  }, [paymentMethodGroups]);

  const [selectedOption, setSelectedOption] = useState(options.find(e => e.value === value) ?? NO_GROUP_OPTION);

  useEffect(() => {
    if (!success && !hasFetched) {
      void dispatch(getPaymentMethodGroupTopup(country.countryCode)).finally(() => setHasFetched(true));
    }
  }, [country.countryCode, dispatch, success, hasFetched]);

  const onSelect = useCallback(
    (option: Option) => {
      setSelectedOption(option);
      onChange(option.value);
    },
    [onChange],
  );

  return (
    <Select
      options={options}
      selected={selectedOption}
      onSelect={onSelect}
      loading={loading}
    />
  );
};

export default GroupSelect;
