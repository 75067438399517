import { createAsyncThunk } from '@reduxjs/toolkit';

import authAxios from '@/auth/axios';
import { type TransactionType as TransactionTypeEnum } from '@/types/Transaction';
import {
  type SearchChannelResponse,
  type LimitLevelsResponse,
  type MoneyFlowTypeEnum,
  // Get
  type GetLimitsParams,
  type GetLimitsResponse,
  // Passed values to store
  type MoneyInSplitEmum,
  type MoneyOutSplitEmum,
  type PeriodTypeEnum,
  // Create
  type CreateLimitBody,
  type CreateLimitResponse,
  // Update
  type UpdateLimitBody,
  type UpdateLimitResponse,
} from '@/types/Limits';
import { COUNTRY_CONFIGURATION_LIMITS_CURRENCY } from '@/utils/constants';
import { errorToast, successToast } from '@/utils/toastMessage';

export const getLimitLevels = createAsyncThunk('limits.getLimitLevels', async (_, { rejectWithValue }) => {
  try {
    const response = await authAxios.get<LimitLevelsResponse>('/limit-configurations/limit-levels');

    return response.data.data.data;
  } catch (err: any) {
    errorToast();
    return rejectWithValue(err);
  }
});

export const getLimits = createAsyncThunk('limits.getLimits', async (params: GetLimitsParams, { rejectWithValue }) => {
  try {
    const { data } = await authAxios.get<GetLimitsResponse>(
      `/limit-configurations/${COUNTRY_CONFIGURATION_LIMITS_CURRENCY}`,
      { params },
    );

    return data.data;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

interface ChannelsSearchParams {
  transactionType?: TransactionTypeEnum;
}

export const searchChannels = createAsyncThunk(
  'limits.getChannels',
  async (query: ChannelsSearchParams, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<SearchChannelResponse>('/channels/search', { params: query });

      return { ...data.data, ...query };
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

interface CreateLimitParams {
  data: CreateLimitBody;
  limitsSplit: MoneyInSplitEmum | MoneyOutSplitEmum;
}

export const createLimit = createAsyncThunk(
  'limits.createLimit',
  async ({ data, limitsSplit }: CreateLimitParams, { rejectWithValue }) => {
    try {
      const response = await authAxios.post<CreateLimitResponse>('/limit-configurations', data);

      successToast('toast-messages.limit-created');
      return { data: response.data, limitsSplit };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

type UpdateLimitParamsBase<T> = {
  data: UpdateLimitBody;
  limitId: string;
  period: PeriodTypeEnum;
} & T;

type UpdateLimitParamsMoneyIn = UpdateLimitParamsBase<{
  limitsSplit: MoneyInSplitEmum;
  moneyFlowType: MoneyFlowTypeEnum.MoneyIn;
}>;

type UpdateLimitParamsMoneyOut = UpdateLimitParamsBase<{
  limitsSplit: MoneyOutSplitEmum;
  moneyFlowType: MoneyFlowTypeEnum.MoneyOut;
}>;

type UpdateLimitParams = UpdateLimitParamsMoneyIn | UpdateLimitParamsMoneyOut;

export const updateLimit = createAsyncThunk(
  'limits.updateLimit',
  async ({ data, limitId, period, limitsSplit, moneyFlowType }: UpdateLimitParams, { rejectWithValue }) => {
    try {
      const response = await authAxios.put<UpdateLimitResponse>(`/limit-configurations/${limitId}`, data);

      successToast('toast-messages.limit-updated');
      return {
        data: response.data.data,
        limitId,
        period,
        limitsSplit,
        moneyFlowType,
      };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
