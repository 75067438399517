import { useEffect, useCallback, type FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Button from '@/components/common/button/Button';
import { getPaymentMethodTopupByGroup } from '@/lib/redux/slices/payment-methods-topup/actions';
import { openModal, reset, emptyMethod } from '@/lib/redux/slices/payment-methods-topup/slice';
import Spinner from '@/components/common/spinners/Spinner';
import BackButton from '@/components/common/button/implementations/BackButton';
import Table from '@/components/common/tables/Table';
import TablePagination from '@/components/common/table-pagination/TablePagination';
import layoutStyles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { Permissions } from '@/types/UserPermissions';
import { green } from '@/utils/colors/green';
import { PAYMENT_METHODS_TOPUP_NO_GROUP_ID } from '@/utils/constants';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';
import usePagination from '@/utils/hooks/usePagination';
import { usePermissions } from '@/utils/hooks/usePermissions';

import PaymentMethodTableRow from './PaymentMethodsTableRow';
import styles from './PaymentMethods.module.scss';

const PaymentMethodsTable: FC = () => {
  const { t } = useTranslation('configurations');
  const { groupId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paymentMethods, totalCount, page } = useAppSelector(({ paymentMethodTopup }) => paymentMethodTopup);
  const loading = useAppSelector(({ paymentMethodTopup }) => paymentMethodTopup.loading);
  const successModalCall = useAppSelector(({ paymentMethodTopup }) => paymentMethodTopup.modal.success);
  const countryCode = useAppSelector(({ country }) => country.country.countryCode);
  const [currentPage, setCurrentPage] = usePagination(page);
  const [itemsPerPage] = useItemsPerPage();

  const hasEditPermission = usePermissions(Permissions.countryConfigEdit);
  const isReadOnly = !hasEditPermission;

  useEffect(() => {
    if (countryCode && groupId) {
      void dispatch(
        getPaymentMethodTopupByGroup({ countryCode, groupId, limit: itemsPerPage, offset: currentPage - 1 }),
      );
    }
  }, [dispatch, countryCode, groupId, currentPage, itemsPerPage]);

  useEffect(() => {
    if (successModalCall && countryCode && groupId) {
      void dispatch(
        getPaymentMethodTopupByGroup({ countryCode, groupId, limit: itemsPerPage, offset: currentPage - 1 }),
      );
    }
  }, [dispatch, countryCode, groupId, successModalCall, currentPage, itemsPerPage]);

  const hangleBackButtonClick = useCallback((): void => {
    navigate(`/configurations/countries/${countryCode}/payment-methods-top-up`);
    dispatch(reset());
  }, [navigate, dispatch, countryCode]);

  const handleOpenModal = useCallback(() => {
    const data =
      groupId !== PAYMENT_METHODS_TOPUP_NO_GROUP_ID
        ? { ...emptyMethod, groupId, countryCode }
        : { ...emptyMethod, countryCode };

    dispatch(openModal({ modalKey: 'modal', mode: 'opened-create', data }));
  }, [dispatch, groupId, countryCode]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <>
          <div className={layoutStyles.title}>
            <div className={layoutStyles.backButton}>
              <BackButton
                onClick={hangleBackButtonClick}
                color={green.cssColor}
              >
                {t('payment-method-topup.back-to-payments-groups')}
              </BackButton>
            </div>
            <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
              <div className={layoutStyles.buttons}>
                <Button
                  textTransform='uppercase'
                  onClick={handleOpenModal}
                >
                  + {t('payment-method-topup.button-new-method')}
                </Button>
              </div>
            </UserPermissionsGuard>
          </div>
          <div className={styles.emptyCard}>
            <Table cols={isReadOnly ? 6 : 7}>
              <Table.Head>{t('payment-method-topup.name')}</Table.Head>
              <Table.Head>{t('payment-method-topup.id')}</Table.Head>
              <Table.Head>{t('payment-method-topup.order')}</Table.Head>
              <Table.Head>{t('payment-method-topup.channel-id')}</Table.Head>
              <Table.Head>{t('payment-method-topup.enabled')}</Table.Head>
              <Table.Head>{t('payment-method-topup.allowed-in-checkout')}</Table.Head>
              {!isReadOnly && <Table.Head>{t('payment-method-topup.actions')}</Table.Head>}
              {paymentMethods.map(paymentMethod => {
                return (
                  <PaymentMethodTableRow
                    key={paymentMethod.id}
                    paymentMethod={paymentMethod}
                    isReadOnly={isReadOnly}
                  />
                );
              })}
            </Table>
            <TablePagination
              count={totalCount}
              page={page}
              setPage={setCurrentPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentMethodsTable;
