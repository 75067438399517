import Spinner from './Spinner';

export default function ModalSpinner(): React.JSX.Element {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 5,
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: 'inherit',
      }}
    >
      <Spinner />
    </div>
  );
}
