import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { useTranslation } from 'react-i18next';

import { type WithdrawalFeeAmount, type PaymentMethodWithdrawal } from '@/types/PaymentMethodWithdrawal';
import type PaymentMethodWithdrawals from '@/types/PaymentMethodWithdrawal';
import PaymentOption from '@/components/configurations/payment-methods-withdrawals/PaymentOption';
import {
  getPaymentMethodsWithdrawals,
  updatePaymentMethodsWithdrawals,
  savePaymentMethodsWithdrawals,
} from '@/lib/redux/slices/payment-methods-withdrawals/actions';
import Spinner from '@/components/common/spinners/Spinner';
import Button from '@/components/common/button/Button';
import { ButtonContainer } from './styles';
import { PaymentProviderId } from '@/utils/constants';
import { reset } from '@/lib/redux/slices/payment-methods-withdrawals/slice';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';

type FormValues = string | boolean | number | { value: number; currencyCode: string } | undefined;

const PaymentMethodsWithdrawals = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('configurations');
  const { country } = useAppSelector(state => state.country);
  const hasEditPermission = usePermissions(Permissions.countryConfigEdit);
  const { paymentMethodsWithdrawals, loading, createSuccess, updateSuccess } = useAppSelector(
    state => state.paymentMethodsWithdrawals,
  );
  const editedPaymentMethodCacheRef = useRef<PaymentMethodWithdrawal>();
  const editedFeeAmountCacheRef = useRef<WithdrawalFeeAmount>();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodWithdrawal>({
    name: '',
    description: '',
    isEnabled: false,
    imageUrl: '',
    minAmount: {
      value: 0,
      currencyCode: '',
    },
    currencyCode: '',
    nameTranslationKey: '',
    descriptionTranslationKey: '',
    channelId: '',
  });
  const [feeAmount, setFeeAmount] = useState<WithdrawalFeeAmount>({
    value: 0,
    currencyCode: '',
  });

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (country?.countryCode) {
      void dispatch(getPaymentMethodsWithdrawals(country.countryCode));
    }
  }, [country, dispatch]);

  useEffect(() => {
    if ((createSuccess || updateSuccess) && country?.countryCode) {
      void dispatch(getPaymentMethodsWithdrawals(country.countryCode));
    }
  }, [createSuccess, updateSuccess, country, dispatch]);

  useEffect(() => {
    if (paymentMethodsWithdrawals?.paymentMethods[0]) {
      setPaymentMethod(paymentMethodsWithdrawals?.paymentMethods[0]);
    }

    if (paymentMethodsWithdrawals?.feeAmount) {
      setFeeAmount(paymentMethodsWithdrawals?.feeAmount);
    }
  }, [paymentMethodsWithdrawals]);

  const isFormValid = useMemo(() => {
    const isValueValid = (value: FormValues): boolean => {
      switch (typeof value) {
        case 'undefined':
          return false;
        case 'string':
          return value.length > 0;
        case 'number':
          return value >= 0;
        case 'object':
          return value.value >= 0;
        default:
          return true;
      }
    };

    // Create
    if (!paymentMethodsWithdrawals) {
      const isValid = Object.values(paymentMethod).every(isValueValid) && Object.values(feeAmount).every(isValueValid);
      return isValid;
    }

    // Edit
    if (paymentMethodsWithdrawals) {
      // First data load, cache it for change checks
      if (
        editedPaymentMethodCacheRef.current === undefined &&
        paymentMethod.paymentMethodId !== undefined &&
        editedFeeAmountCacheRef.current === undefined
      ) {
        editedPaymentMethodCacheRef.current = paymentMethod;
        editedFeeAmountCacheRef.current = feeAmount;

        return false;
      }

      const hasValidPaymentMethodChange = Object.entries(paymentMethod).some(([key, value]) => {
        if (!editedPaymentMethodCacheRef.current) {
          return false;
        }

        const castedValue = value as FormValues;
        const cacheValue = editedPaymentMethodCacheRef.current[key as keyof PaymentMethodWithdrawal];

        // Early return if values are identical
        if (castedValue === cacheValue) {
          return false;
        }

        // Handle string value
        if (typeof castedValue === 'string') {
          const trimmedValue = castedValue.trim();
          return trimmedValue !== cacheValue;
        }

        // Handle non-string values
        return castedValue !== cacheValue;
      });

      const hasValidPaymentMethod = Object.entries(paymentMethod).some(([key, value]) => {
        if (!editedPaymentMethodCacheRef.current) {
          return false;
        }

        const castedValue = value as FormValues;

        // Handle string values
        if (typeof castedValue === 'string') {
          const trimmedValue = castedValue.trim();
          isValueValid(trimmedValue);
        }

        // Handle non-string values
        return isValueValid(castedValue);
      });

      const hasValidFeeAmountChange =
        feeAmount.value !== editedFeeAmountCacheRef.current?.value ||
        feeAmount.currencyCode !== editedFeeAmountCacheRef.current?.currencyCode;

      const hasValidFeeAmount = feeAmount.value >= 0 && feeAmount.currencyCode.length > 0;

      return (hasValidFeeAmountChange || hasValidPaymentMethodChange) && hasValidFeeAmount && hasValidPaymentMethod;
    }

    // Clear change checks cached data
    editedPaymentMethodCacheRef.current = undefined;
    return false;
  }, [paymentMethod, feeAmount, paymentMethodsWithdrawals]);

  const onSubmit = useCallback((): void => {
    const isUpdate = !!paymentMethodsWithdrawals?.id;

    const createSubmitObject = (): PaymentMethodWithdrawals => {
      let submitData;

      if (isUpdate) {
        submitData = {
          feeAmount,
          paymentMethods: [paymentMethod],
        };
      } else {
        const { name, ...paymentMethodTemp } = paymentMethod;
        const paymentMethodBodyData = {
          paymentOptionName: paymentMethod.name,
          ...paymentMethodTemp,
          minAmount: {
            value: paymentMethodTemp.minAmount.value,
            currencyCode: country.currencies[0].currencyCode,
          },
        };
        submitData = {
          feeAmount,
          countryCode: country.countryCode,
          paymentMethods: [paymentMethodBodyData],
          providers: [
            {
              providerId: PaymentProviderId,
              priority: 1,
            },
          ],
        };
      }

      return submitData as PaymentMethodWithdrawals;
    };

    if (isFormValid) {
      if (isUpdate) {
        void dispatch(
          updatePaymentMethodsWithdrawals({
            data: createSubmitObject(),
            methodsId: paymentMethodsWithdrawals.id || '',
          }),
        );
      } else {
        void dispatch(savePaymentMethodsWithdrawals(createSubmitObject()));
      }
    }
  }, [paymentMethodsWithdrawals, dispatch, feeAmount, paymentMethod, country, isFormValid]);

  return (
    <div>
      {loading && <Spinner />}
      {!loading && (
        <>
          <PaymentOption
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            feeAmount={feeAmount}
            setFeeAmount={setFeeAmount}
            hasEditPermission={hasEditPermission}
          />
          <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
            <ButtonContainer>
              <Button
                textTransform='uppercase'
                onClick={onSubmit}
                disabled={!isFormValid || loading}
              >
                {t('pm-withdrawals.submit-configuration')}
              </Button>
            </ButtonContainer>
          </UserPermissionsGuard>
        </>
      )}
    </div>
  );
};

export default PaymentMethodsWithdrawals;
