import { type FC } from 'react';

import FilledCircleIcon from '@/components/icons/FilledCircleIcon';
import Typography from '@/components/common/typography/Typography';
import CircleTickIcon from '@/components/icons/CircleTickIcon';

import { AccordeonTitleStyled } from './styles';

interface AccordionTitleProps {
  title: string;
  isValid?: boolean;
}

const AccordionTitle: FC<AccordionTitleProps> = ({ title, isValid = false }) => {
  return (
    <AccordeonTitleStyled>
      {isValid && <CircleTickIcon />}
      {!isValid && <FilledCircleIcon />}
      <Typography variant='p2MediumTight'> {title}</Typography>
    </AccordeonTitleStyled>
  );
};

export default AccordionTitle;
