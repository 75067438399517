import { useCallback, useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/lib/redux';

import Input from '@/components/common/inputs/Input';
import {
  type CreateLimitBody,
  type LimitsByPeriod,
  MoneyFlowTypeEnum,
  PeriodTypeEnum,
  PeriodTypeStoreKeysEnum,
  MoneyInSplitEmum,
  LimitTypeEnum,
} from '@/types/Limits';
import { createLimit, searchChannels, updateLimit } from '@/lib/redux/slices/limits/actions';
import { Permissions, TransactionType as TransactionTypeEnum } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { groupLimitsByPeriodType } from '@/utils/limits';
import { parseNumberOrDefault } from '@/utils/helperFunctions';

import { GridItem, GridContainer } from './styles';

const PaymentToCustomer: FC = () => {
  const { t } = useTranslation('configurations');
  const [paymentToCustomerByPeriodType, setPaymentToCustomerByPeriodType] = useState<LimitsByPeriod>();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const { paymentToCustomerLimits, selectedLevel, pToCChannels, errorFieldUpdate } = useAppSelector(
    state => state.limits,
  );
  const { countryCode } = useAppSelector(state => state.country.country);
  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(searchChannels({ transactionType: TransactionTypeEnum.PaymentToCustomer }));
  }, [dispatch]);

  useEffect(() => {
    if (paymentToCustomerLimits) {
      setPaymentToCustomerByPeriodType(groupLimitsByPeriodType(paymentToCustomerLimits));
    }
  }, [paymentToCustomerLimits]);

  useEffect(() => {
    if (errorFieldUpdate) {
      setHasChanged(true);
    }
  }, [errorFieldUpdate]);

  const updatePaymentToCustomerLimit = useCallback((periodType: PeriodTypeEnum, value: string) => {
    setPaymentToCustomerByPeriodType(prevLimits => {
      if (!prevLimits) return prevLimits;
      const storeKey = PeriodTypeStoreKeysEnum[periodType];

      return {
        ...prevLimits,
        [storeKey]: {
          ...prevLimits[storeKey],
          value: parseNumberOrDefault(value, prevLimits[storeKey]?.value || 0),
        },
      };
    });

    setHasChanged(true);
  }, []);

  const handleInputBlur = useCallback(
    (periodType: PeriodTypeEnum) => {
      const storeKey = PeriodTypeStoreKeysEnum[periodType];
      const limitData = paymentToCustomerByPeriodType?.[storeKey];

      if (limitData && limitData?.value >= 0 && hasChanged) {
        if (limitData.id === undefined) {
          const requestBodyData: CreateLimitBody = {
            limitConfigurations: [
              {
                channelId: pToCChannels?.[0]?.id || '',
                limitLevel: selectedLevel?.text || '',
                periodType,
                value: Number(limitData.value),
                limitType: LimitTypeEnum.Amount,
                moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
                countryCode,
              },
            ],
          };

          void dispatch(createLimit({ data: requestBodyData, limitsSplit: MoneyInSplitEmum.PaymentToCustomer }));
        } else {
          void dispatch(
            updateLimit({
              data: { value: Number(limitData?.value) },
              limitId: limitData?.id || '',
              period: limitData.periodType,
              limitsSplit: MoneyInSplitEmum.PaymentToCustomer,
              moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
            }),
          );
        }

        setHasChanged(false);
      }
    },
    [dispatch, countryCode, pToCChannels, selectedLevel, paymentToCustomerByPeriodType, hasChanged],
  );

  if (!paymentToCustomerByPeriodType) {
    return null;
  }

  return (
    <GridContainer>
      <div className='inner-container'>
        <GridItem $cell={[1, 2]}>{t('limits.payment-to-customer')}</GridItem>
        <GridItem $cell={[3, 1]}>
          <Input
            onChange={(value: string) => updatePaymentToCustomerLimit(PeriodTypeEnum.ByTransaction, value)}
            value={paymentToCustomerByPeriodType?.byTransaction?.value.toString() || ''}
            onBlur={() => handleInputBlur(PeriodTypeEnum.ByTransaction)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[4, 1]}>
          <Input
            onChange={(value: string) => updatePaymentToCustomerLimit(PeriodTypeEnum.Daily, value)}
            value={paymentToCustomerByPeriodType?.daily?.value.toString() || ''}
            onBlur={() => handleInputBlur(PeriodTypeEnum.Daily)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[5, 1]}>
          <Input
            onChange={(value: string) => updatePaymentToCustomerLimit(PeriodTypeEnum.Weekly, value)}
            value={paymentToCustomerByPeriodType?.weekly?.value.toString() || ''}
            onBlur={() => handleInputBlur(PeriodTypeEnum.Weekly)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[6, 1]}>
          <Input
            onChange={(value: string) => updatePaymentToCustomerLimit(PeriodTypeEnum.Monthly, value)}
            value={paymentToCustomerByPeriodType?.monthly?.value.toString() || ''}
            onBlur={() => handleInputBlur(PeriodTypeEnum.Monthly)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[7, 1]}>
          <Input
            onChange={(value: string) => updatePaymentToCustomerLimit(PeriodTypeEnum.Lifetime, value)}
            value={paymentToCustomerByPeriodType?.lifetime?.value.toString() || ''}
            onBlur={() => handleInputBlur(PeriodTypeEnum.Lifetime)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
      </div>
    </GridContainer>
  );
};

export default PaymentToCustomer;
