import { useState, useCallback, useEffect, type FC } from 'react';
import { GridInputRow } from './styles';
import Input from '@/components/common/inputs/Input';
import {
  type LimitDatum,
  type CreateLimitBody,
  type LimitsByPeriod,
  MoneyFlowTypeEnum,
  PeriodTypeEnum,
  PeriodTypeStoreKeysEnum,
  MoneyInSplitEmum,
  LimitTypeEnum,
} from '@/types/Limits';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { createLimit, updateLimit } from '@/lib/redux/slices/limits/actions';
import { type PaymentMethod } from '@/types/PaymentMethod';
import { groupLimitsByPeriodType } from '@/utils/limits';
import { Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { parseNumberOrDefault } from '@/utils/helperFunctions';

interface TopUpInputProps {
  type: string;
  method: PaymentMethod;
  limits?: LimitDatum[];
}

const TopUpInput: FC<TopUpInputProps> = props => {
  const { type, method, limits } = props;

  const dispatch = useAppDispatch();

  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);

  const { selectedLevel } = useAppSelector(state => state.limits);

  const [topupByPeriodType, setTopupByPeriodType] = useState<LimitsByPeriod>();

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (limits) {
      setTopupByPeriodType(groupLimitsByPeriodType(limits));
    }
  }, [limits]);

  const updateTransferLimit = useCallback((periodType: PeriodTypeEnum, value: string) => {
    setTopupByPeriodType(prevLimits => {
      if (!prevLimits) return prevLimits;
      const storeKey = PeriodTypeStoreKeysEnum[periodType];

      return {
        ...prevLimits,
        [storeKey]: {
          ...prevLimits[storeKey],
          value: parseNumberOrDefault(value, prevLimits[storeKey]?.value || 0),
        },
      };
    });

    setHasChanged(true);
  }, []);

  const handleInputBlur = useCallback(
    (periodType: PeriodTypeEnum) => {
      const storeKey = PeriodTypeStoreKeysEnum[periodType];
      const limitData = topupByPeriodType?.[storeKey];
      if (limitData && limitData?.value >= 0 && hasChanged) {
        if (limitData.id === undefined) {
          const requestBodyData: CreateLimitBody = {
            limitConfigurations: [
              {
                channelId: method.channelId,
                limitLevel: selectedLevel?.text || '',
                periodType,
                value: Number(limitData.value),
                limitType: LimitTypeEnum.Amount,
                moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
                countryCode: method.countryCode,
              },
            ],
          };
          void dispatch(createLimit({ data: requestBodyData, limitsSplit: MoneyInSplitEmum.Topup }));
        } else {
          void dispatch(
            updateLimit({
              data: { value: Number(limitData?.value) },
              limitId: limitData.id || '',
              period: limitData.periodType,
              limitsSplit: MoneyInSplitEmum.Topup,
              moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
            }),
          );
        }

        setHasChanged(false);
      }
    },
    [dispatch, method.countryCode, method.channelId, selectedLevel, topupByPeriodType, hasChanged],
  );

  if (!topupByPeriodType) {
    return null;
  }

  return (
    <GridInputRow>
      <div className='type-cell'>{type}</div>
      <div className='transaction-cell'>
        <Input
          value={topupByPeriodType?.byTransaction?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(PeriodTypeEnum.ByTransaction, value)}
          onBlur={() => handleInputBlur(PeriodTypeEnum.ByTransaction)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='daily-cell'>
        <Input
          value={topupByPeriodType?.daily?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(PeriodTypeEnum.Daily, value)}
          onBlur={() => handleInputBlur(PeriodTypeEnum.Daily)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='weekly-cell'>
        <Input
          value={topupByPeriodType?.weekly?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(PeriodTypeEnum.Weekly, value)}
          onBlur={() => handleInputBlur(PeriodTypeEnum.Weekly)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='monthly-cell'>
        <Input
          value={topupByPeriodType?.monthly?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(PeriodTypeEnum.Monthly, value)}
          onBlur={() => handleInputBlur(PeriodTypeEnum.Monthly)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='lifetime-cell'>
        <Input
          value={topupByPeriodType?.lifetime?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(PeriodTypeEnum.Lifetime, value)}
          onBlur={() => handleInputBlur(PeriodTypeEnum.Lifetime)}
          disabled={!hasCountryEditPermission}
        />
      </div>
    </GridInputRow>
  );
};

export default TopUpInput;
