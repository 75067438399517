import { type Channel } from '@/types/Channel';

export interface LimitLevel {
  id: string;
  name: string;
}

export enum MoneyFlowTypeEnum {
  MoneyIn = 'MoneyIn',
  MoneyOut = 'MoneyOut',
}

export enum LimitTypeEnum {
  Amount = 'Amount',
  Quantity = 'Quantity',
}

export enum LimitLevelEnum {
  Standard = 'Standard',
  Advanced = 'Advanced',
  Vip = 'VIP',
  Entry = 'Entry',
}

/**
 * Descrides possible values in `periodType` field
 */
export enum PeriodTypeEnum {
  ByTransaction = 'ByTransaction',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Annual = 'Annual',
  Lifetime = 'Lifetime',
}

/**
 * Descrides Keys in components state
 */
export enum PeriodTypeStoreKeysEnum {
  ByTransaction = 'byTransaction',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Annual = 'annualy',
  Lifetime = 'lifetime',
}

export interface LimitDatum {
  id: string;
  countryCode: string;
  limitLevel: string;
  limitType: LimitTypeEnum;
  moneyFlowType: MoneyFlowTypeEnum;
  periodType: PeriodTypeEnum;
  value: number;
  channel: LimitChannelData;
}

export type GetLimitsParams = Partial<{
  channelId: string;
  countryCode: string;
  limitLevel: LimitLevelEnum;
  limitType: LimitTypeEnum;
  moneyFlowType: MoneyFlowTypeEnum;
  periodType: PeriodTypeEnum;
}>;

export interface GetLimitsResponse {
  data: { limits: LimitDatum[] };
}

export interface CreateLimitBody {
  limitConfigurations: [Omit<LimitDatum, 'id' | 'channel'> & { channelId: string }];
}

export interface CreateLimitResponse {
  data: [Omit<LimitDatum, 'channel'> & { channelId: string }];
}

export interface UpdateLimitBody {
  value: number;
}

export interface UpdateLimitResponse {
  data: Pick<LimitDatum, 'id' | 'value'>;
}

export interface LimitLevelsResponse {
  data: {
    data: LimitLevel[];
  };
}

export interface LimitChannelData {
  id?: string;
  name: string;
  transactionType: string;
}

export interface SearchChannelResponse {
  data: {
    data: Channel[];
  };
}

export type LimitsByPeriod = Partial<Record<PeriodTypeStoreKeysEnum, LimitDatum>>;

export interface CurrencyMultiplier {
  currencyCode: string;
  multiplier: number;
}

// The below enums describe redux store data keys
// TODO: Update these in limist store refactoring
/**
 * Describes Money In limits data keys in redux store
 */
export enum MoneyInSplitEmum {
  PaymentToCustomer = 'paymentToCustomerLimits',
  Transfer = 'transferLimits',
  Topup = 'topup',
}
// Payment from customer - PaymentFromCustomer
// Peer to peer transfer - Transfer
// Prepaid ATM withdrawal - Card + ATM_WITHDRAWAL
// Prepaid POS spend - Card + PRESENT_SPENT
// Prepaid Virtual spend -  Card + NOT_PRESENT_SPENT
/**
 * Describes Money Out limits data keys in redux store
 */
export enum MoneyOutSplitEmum {
  PaymentFromCustomer = 'paymentFromCustomer',
  PeerToPeerTransfer = 'peerToPeerTransfer',
  CardATMWithdrawal = 'cardATMWithdrawal',
  CardPOSSpent = 'cardPOSSpent',
  CardVirtualSpent = 'cardVirtualSpent',
}
