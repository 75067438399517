import { useState, useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '@/components/common/accordion/Accordion';
import AccordionTitle from './AccordionTitle';
import AccordionContentHeader from './AccordionContentHeader';
import PaymentToCustomer from './PaymentToCustomer';
import TopUp from './TopUp';
import PeerToPeerTransfer from './PeerToPeerTransfer';

const MoneyIn: FC = () => {
  const { t } = useTranslation('configurations');
  const [active, setActive] = useState(false);

  const handleClick = useCallback(() => {
    setActive(prev => !prev);
  }, []);

  return (
    <Accordion
      title={
        <AccordionTitle
          title={t('limits.money-in-title')}
          isValid
        />
      }
      content={
        <>
          <AccordionContentHeader />
          <PaymentToCustomer />
          <PeerToPeerTransfer />
          <TopUp />
        </>
      }
      isActive={active}
      onClick={handleClick}
    />
  );
};

export default MoneyIn;
