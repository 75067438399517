import { createSlice } from '@reduxjs/toolkit';

import { type Channel, CardChannelEnum } from '@/types/Channel';
import {
  type LimitDatum,
  type LimitsByPeriod,
  type LimitLevel,
  type MoneyOutSplitEmum,
  MoneyInSplitEmum,
  MoneyFlowTypeEnum,
  PeriodTypeStoreKeysEnum,
} from '@/types/Limits';
import { TransactionType as TransactionTypeEnum } from '@/types/Transaction';
import { type Option } from '@/utils/interfaces';
import { groupLimitsByPeriodType, getLimitsPerSplit } from '@/utils/limits';

import { getLimitLevels, getLimits, searchChannels, updateLimit, createLimit } from './actions';

interface LimitsState {
  limitLevels: LimitLevel[] | undefined;
  selectedLevel: Option | undefined;
  // TODO: Group Money In limits data under `moneyInLimits`
  paymentToCustomerLimits: LimitDatum[];
  pToCChannels: Channel[];
  transferLimits: LimitDatum[];
  transferChannels: Channel[];
  topup: LimitDatum[];
  topupChannels: Channel[];
  moneyOutLimits: {
    [key in MoneyOutSplitEmum]: LimitsByPeriod;
  };
  moneyOutLimitChannels: {
    [key in MoneyOutSplitEmum as `${key}Channels`]: Channel[];
  };
  loading: boolean;
  success: boolean;
  errorFieldUpdate: boolean;
}

const initialState: LimitsState = {
  limitLevels: undefined,
  selectedLevel: undefined,
  paymentToCustomerLimits: [],
  pToCChannels: [],
  transferLimits: [],
  transferChannels: [],
  topup: [],
  topupChannels: [],
  moneyOutLimits: {
    paymentFromCustomer: {},
    peerToPeerTransfer: {},
    cardATMWithdrawal: {},
    cardPOSSpent: {},
    cardVirtualSpent: {},
  },
  moneyOutLimitChannels: {
    paymentFromCustomerChannels: [],
    peerToPeerTransferChannels: [],
    cardATMWithdrawalChannels: [],
    cardPOSSpentChannels: [],
    cardVirtualSpentChannels: [],
  },
  loading: false,
  success: false,
  errorFieldUpdate: false,
};

export const limits = createSlice({
  name: 'limits',
  initialState,
  reducers: {
    setSelectedLimitLevel(
      state,
      action: {
        payload: Option | undefined;
      },
    ) {
      state.selectedLevel = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getLimitLevels.pending, state => {
      state.loading = true;
    });
    builder.addCase(getLimitLevels.fulfilled, (state, action) => {
      state.limitLevels = action.payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getLimitLevels.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(getLimits.pending, state => {
      state.loading = true;
    });
    builder.addCase(getLimits.fulfilled, (state, action) => {
      const { limits } = action.payload;
      // Money In splits
      state.paymentToCustomerLimits = getLimitsPerSplit(limits, {
        channelTransactionType: TransactionTypeEnum.PaymentToCustomer,
        limitMoneyFlowType: MoneyFlowTypeEnum.MoneyIn,
      });
      state.transferLimits = getLimitsPerSplit(limits, {
        channelTransactionType: TransactionTypeEnum.Transfer,
        limitMoneyFlowType: MoneyFlowTypeEnum.MoneyIn,
      });
      state.topup = getLimitsPerSplit(limits, {
        channelTransactionType: TransactionTypeEnum.Topup,
        limitMoneyFlowType: MoneyFlowTypeEnum.MoneyIn,
      });

      // Money Out splits
      state.moneyOutLimits.peerToPeerTransfer = groupLimitsByPeriodType(
        getLimitsPerSplit(limits, {
          channelTransactionType: TransactionTypeEnum.Transfer,
          limitMoneyFlowType: MoneyFlowTypeEnum.MoneyOut,
        }),
      );
      state.moneyOutLimits.paymentFromCustomer = groupLimitsByPeriodType(
        getLimitsPerSplit(limits, {
          channelTransactionType: TransactionTypeEnum.PaymentFromCustomer,
          limitMoneyFlowType: MoneyFlowTypeEnum.MoneyOut,
        }),
      );
      state.moneyOutLimits.cardATMWithdrawal = groupLimitsByPeriodType(
        getLimitsPerSplit(limits, {
          channelTransactionType: TransactionTypeEnum.Card,
          limitMoneyFlowType: MoneyFlowTypeEnum.MoneyOut,
          channelName: CardChannelEnum.ATMWithdrawal,
        }),
      );
      state.moneyOutLimits.cardPOSSpent = groupLimitsByPeriodType(
        getLimitsPerSplit(limits, {
          channelTransactionType: TransactionTypeEnum.Card,
          limitMoneyFlowType: MoneyFlowTypeEnum.MoneyOut,
          channelName: CardChannelEnum.POSWithdrawal,
        }),
      );
      state.moneyOutLimits.cardVirtualSpent = groupLimitsByPeriodType(
        getLimitsPerSplit(limits, {
          channelTransactionType: TransactionTypeEnum.Card,
          limitMoneyFlowType: MoneyFlowTypeEnum.MoneyOut,
          channelName: CardChannelEnum.VirtualWithdrawal,
        }),
      );

      state.loading = false;
      state.success = true;
    });
    builder.addCase(getLimits.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(searchChannels.pending, state => {
      state.loading = true;
    });
    builder.addCase(searchChannels.fulfilled, (state, action) => {
      const { transactionType, data } = action.payload;

      if (transactionType === TransactionTypeEnum.PaymentToCustomer) {
        // Money In
        state.pToCChannels = data;
      } else if (transactionType === TransactionTypeEnum.Topup) {
        // Money In
        state.topupChannels = data;
      } else if (transactionType === TransactionTypeEnum.Transfer) {
        // Money In
        state.transferChannels = data;
        // Money Out
        state.moneyOutLimitChannels.peerToPeerTransferChannels = data;
      } else if (transactionType === TransactionTypeEnum.PaymentFromCustomer) {
        // Money Out
        state.moneyOutLimitChannels.paymentFromCustomerChannels = data;
      } else if (transactionType === TransactionTypeEnum.Card) {
        // Money Out
        data.forEach(channel => {
          if (channel.name === CardChannelEnum.ATMWithdrawal) {
            state.moneyOutLimitChannels.cardATMWithdrawalChannels = [channel];
          } else if (channel.name === CardChannelEnum.POSWithdrawal) {
            state.moneyOutLimitChannels.cardPOSSpentChannels = [channel];
          } else if (channel.name === CardChannelEnum.VirtualWithdrawal) {
            state.moneyOutLimitChannels.cardVirtualSpentChannels = [channel];
          }
        });
      }

      state.loading = false;
      state.success = true;
    });
    builder.addCase(searchChannels.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(updateLimit.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateLimit.fulfilled, (state, action) => {
      const { moneyFlowType, limitsSplit, period, data, limitId } = action.payload;

      if (moneyFlowType === MoneyFlowTypeEnum.MoneyOut) {
        // Money Out
        const limitKey = limitsSplit as MoneyOutSplitEmum;
        const periodKey = PeriodTypeStoreKeysEnum[period];
        const existingLimit = state.moneyOutLimits[limitKey][periodKey];

        if (existingLimit && existingLimit.id === data.id) {
          state.moneyOutLimits[limitKey][periodKey] = {
            ...existingLimit,
            value: data.value,
          };
        }
      } else if (limitsSplit === MoneyInSplitEmum.PaymentToCustomer) {
        // Money In
        state.paymentToCustomerLimits = state.paymentToCustomerLimits.map(limit =>
          limit.id === limitId ? { ...limit, value: data.value } : limit,
        );
      } else if (limitsSplit === MoneyInSplitEmum.Transfer) {
        // Money In
        state.transferLimits = state.transferLimits.map(limit =>
          limit.id === limitId ? { ...limit, value: data.value } : limit,
        );
      } else if (limitsSplit === MoneyInSplitEmum.Topup) {
        // Money In
        state.topup = state.topup.map(limit => (limit.id === limitId ? { ...limit, value: data.value } : limit));
      }

      state.loading = false;
      state.errorFieldUpdate = false;
    });
    builder.addCase(updateLimit.rejected, state => {
      state.loading = false;
      state.errorFieldUpdate = true;
    });
    builder.addCase(createLimit.pending, state => {
      state.loading = true;
    });
    builder.addCase(createLimit.fulfilled, (state, action) => {
      const { limitsSplit, data } = action.payload;

      const limitData = {
        ...data.data[0],
        channel: {
          id: data.data[0].channelId,
          name: '',
          transactionType: '',
        },
      };

      if (limitData.moneyFlowType === MoneyFlowTypeEnum.MoneyOut) {
        // Money Out
        const limitKey = limitsSplit as MoneyOutSplitEmum;
        const periodKey = PeriodTypeStoreKeysEnum[limitData.periodType];
        const limitChannel = state.moneyOutLimitChannels[`${limitKey}Channels`][0];

        limitData.channel.name = limitChannel.name;
        limitData.channel.transactionType = limitChannel.transactionType;

        state.moneyOutLimits[limitKey][periodKey] = limitData;
      } else if (limitsSplit === MoneyInSplitEmum.PaymentToCustomer) {
        // Money In
        limitData.channel.name = state.pToCChannels[0].name;
        limitData.channel.transactionType = state.pToCChannels[0].transactionType;

        state.paymentToCustomerLimits = [...state.paymentToCustomerLimits, limitData];
      } else if (limitsSplit === MoneyInSplitEmum.Transfer) {
        // Money In
        limitData.channel.name = state.transferChannels[0].name;
        limitData.channel.transactionType = state.transferChannels[0].transactionType;

        state.transferLimits = [...state.transferLimits, limitData];
      } else if (limitsSplit === MoneyInSplitEmum.Topup) {
        // Money In
        limitData.channel.name = state.topupChannels[0].name;
        limitData.channel.transactionType = state.topupChannels[0].transactionType;

        state.topup = [...state.topup, limitData];
      }

      state.loading = false;
      state.errorFieldUpdate = false;
    });
    builder.addCase(createLimit.rejected, state => {
      state.loading = false;
      state.errorFieldUpdate = true;
    });
  },
});

export const { reset, setSelectedLimitLevel } = limits.actions;
