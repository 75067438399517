import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';

import CreatePaymentMethodButton from '@/components/configurations/payment-method-group-topup/CreateMethodGroupButton';
import PaymentMethodGroupTopupModal from '@/components/configurations/payment-method-group-topup/PaymentMethodGroupTopupModal';
import PaymentMethodsGroupsTable from '@/components/configurations/payment-method-group-topup/PaymentMethodsGroupsTable';
import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import { Permissions } from '@/types';

const PaymentMethodGroupsTopUp = (): JSX.Element => {
  return (
    <>
      <div className={styles.titleTopup}>
        <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
          <div className={styles.buttons}>
            <CreatePaymentMethodButton />
          </div>
        </UserPermissionsGuard>
      </div>
      <div className={styles.emptyCard}>
        <UserPermissionsGuard permission={Permissions.countryConfigRead}>
          <PaymentMethodsGroupsTable />
        </UserPermissionsGuard>
      </div>
      <PaymentMethodGroupTopupModal />
    </>
  );
};

export default PaymentMethodGroupsTopUp;
