import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';
export const transactionRewardReversal = createAsyncThunk(
  'transaction/transactionRewardReversal',
  async ({ amount, reason, slipId }: { amount: number; reason: string; slipId: string }, thunkApi) => {
    try {
      await authAxios.post('/reward-reversals', { amount, reason, slipId });
      successToast('toast-messages.manual-transaction');
    } catch (err: any) {
      errorToast();
      return thunkApi.rejectWithValue(err);
    }
  },
);
