import { type FeatureFlag } from '@/types/FeatureFlag';
import styles from './FeatureFlagDetails.module.scss';
import Typography from '@/components/common/typography/Typography';
import { useTranslation } from 'react-i18next';
import Input from '@/components/common/inputs/Input';
import { AccordionTitleWithToggle } from '@/components/configurations/';
import Accordion from '@/components/common/accordion/Accordion';
import { type Key, useState } from 'react';
import LinkButton from '../common/button/LinkButton';
import { useFeatureFlagUpdate } from './useFeatureFlagUpdate';
import { Checkbox, toRem } from 'k8-web-lib-tmp';
import styled from 'styled-components';
import { Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import {
  EnablePhoneNumberModal,
  FeatureConfirmModal,
  EnableCountriesModal,
} from '@/components/configurations/feature-flag';

interface Props {
  flag: FeatureFlag;
  key?: Key;
}

export const StyledSpan = styled.span`
  display: inline-block;
  vertical-align: top;
  padding: ${toRem(3)} ${toRem(10)} ${toRem(3)} 0;
`;

export function FeatureFlagDetails({ flag }: Readonly<Props>): JSX.Element {
  const { t } = useTranslation('configurations');

  const [active, setActive] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [updateUrl, setUpdateUrl] = useState('');
  const [updateData, setUpdateData] = useState<{ flagId: string } | FeatureFlag>({ flagId: flag.id });
  const [modalTitle, setModalTitle] = useState('');
  const [isEnablePhoneNumberModalOpen, setIsEnablePhoneNumberModalOpen] = useState(false);
  const [isEnableCountriesModalOpen, setIsEnableCountriesModalOpen] = useState(false);

  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);

  const { isLoading, editFeatureFlag } = useFeatureFlagUpdate(updateUrl, updateData);

  const handleToggleClick = (): void => {
    const requestUrlType = flag.archived ? 'activate' : 'archive';
    setUpdateUrl(`/feature-flags/${flag.id}/${requestUrlType}`);
    setUpdateData({ flagId: flag.id, archived: flag.archived });
    setModalTitle(t(`feature-flags.manage-feature.${flag.archived ? 'activate' : 'archive'}`, { name: flag.id }));
    setIsConfirmationOpen(true);
  };

  const handleEnableAllClick = (): void => {
    setUpdateUrl(`/feature-flags/${flag.id}`);
    setUpdateData({ ...flag, enabledForAll: !flag.enabledForAll });
    setModalTitle(
      t(`feature-flags.manage-feature.${flag.enabledForAll ? 'disable-feature-for-all' : 'enable-feature-for-all'}`, {
        name: flag.id,
      }),
    );
    setIsConfirmationOpen(true);
  };

  const handleEnablePhoneNumberModal = (): void => {
    setIsEnablePhoneNumberModalOpen(true);
  };

  const handleEnableCountryModal = (): void => {
    setIsEnableCountriesModalOpen(true);
  };

  return (
    <>
      <Accordion
        key={flag.id}
        title={
          <AccordionTitleWithToggle
            title={flag.id}
            checked={!flag.archived}
            onChange={handleToggleClick}
          />
        }
        content={
          <div>
            <div className={styles.linkButtonCard}>
              <LinkButton
                target='_blank'
                href={`/feature-flags/history-logs/${flag.id}`}
              >
                {t('feature-flags.button-history-logs')}
              </LinkButton>
            </div>
            <div className={styles.grid}>
              <div>
                <Typography
                  variant='p3Bold'
                  className={styles.enabledForAll}
                >
                  <StyledSpan>{t('feature-flags.manage-feature.enable-for-all')}</StyledSpan>
                  <Checkbox
                    checked={flag.enabledForAll}
                    size={18}
                    onChange={handleEnableAllClick}
                    disabled={!hasCountryEditPermission}
                  />
                </Typography>
              </div>
              <div className={styles.container}>
                <Typography variant='p3Bold'>{t('feature-flags.enabled-phones')}</Typography>
                {flag.phoneNumbers?.map(phoneNumber => (
                  <Input
                    key={phoneNumber}
                    value={phoneNumber}
                    disabled
                  />
                ))}
                {hasCountryEditPermission ? (
                  <LinkButton onClick={handleEnablePhoneNumberModal}>{'+ Enable a new phone'}</LinkButton>
                ) : null}
              </div>
              <div className={styles.container}>
                <Typography variant='p3Bold'>{t('feature-flags.enabled-countries')}</Typography>
                {flag.countries?.map(country => (
                  <Input
                    key={country}
                    value={country}
                    disabled
                  />
                ))}
                {hasCountryEditPermission ? (
                  <LinkButton onClick={handleEnableCountryModal}>{'+ Enable a new country'}</LinkButton>
                ) : null}
              </div>
            </div>
          </div>
        }
        isActive={active}
        isLoading={isLoading}
        onClick={() => setActive(!active)}
      />
      {isConfirmationOpen && (
        <FeatureConfirmModal
          title={modalTitle}
          setIsConfirmationOpen={setIsConfirmationOpen}
          editFeatureFlag={editFeatureFlag}
          flag={flag}
        />
      )}
      {isEnablePhoneNumberModalOpen && (
        <EnablePhoneNumberModal
          flagId={flag.id}
          numbers={flag.phoneNumbers ?? []}
          setIsEnablePhoneNumberModalOpen={setIsEnablePhoneNumberModalOpen}
        />
      )}
      {isEnableCountriesModalOpen && (
        <EnableCountriesModal
          flagId={flag.id}
          countries={flag.countries ?? []}
          setIsEnableCountriesModalOpen={setIsEnableCountriesModalOpen}
        />
      )}
    </>
  );
}
