enum Feature {
  TrustedDevice = 'TrustedDevice',
  EDD = 'EDD',
}

export interface FeatureFlag {
  id: string;
  description: string;
  isEnabled?: boolean;
  enabledForAll: boolean;
  countries?: string[];
  phoneNumbers?: string[];
  archived?: boolean;
}

export interface FeatureFlagHistory {
  featureFlagId: string;
  username?: string;
  changes?: string;
  eventType?: string;
}

export interface ToggleFeatureFlagProps {
  id?: string;
}

export interface AddFlagBody {
  id: string;
  description: string;
  enabledForAll: boolean;
}

export { Feature };
