import { createSlice } from '@reduxjs/toolkit';

import type { PaymentMethodRelation, PaymentMethod } from '@/types/PaymentMethod';
import { type FormModalMode } from '@/utils/interfaces';

import {
  getPaymentMethodTopupByGroup,
  createPaymentMethodTopup,
  updatePaymentMethodTopup,
  getPaymentMethodRelation,
  createPaymentMethodRelation,
  updatePaymentMethodRelation,
} from './actions';

interface ModalState<T> {
  mode: FormModalMode;
  data: T;
  success: boolean;
  loading: boolean;
}

interface PaymentMethodTopupState {
  paymentMethods: PaymentMethod[];
  loading: boolean;
  success: boolean;
  modal: ModalState<PaymentMethod>;
  relationModal: ModalState<PaymentMethodRelation>;
  totalCount: number;
  page: number;
  itemsPerPage: number;
}

type StateWithModals = {
  [K in keyof PaymentMethodTopupState]: PaymentMethodTopupState[K] extends ModalState<any> ? K : never;
}[keyof PaymentMethodTopupState];

export const emptyMethod: PaymentMethod = {
  name: '',
  nameTranslationKey: '',
  groupId: undefined,
  countryCode: '',
  isEnabled: false,
  imageUrl: '',
  channelId: '',
  descriptionTranslationKey: '',
  order: 0,
  minAmount: 0,
  minAmountCurrency: '',
  reason: '',
  allowInCheckout: false,
  identityNumberSource: '',
};

export const emptyRelation: PaymentMethodRelation = {
  kuadyPaymentMethodId: '',
  pspPaymentMethodId: '',
  name: '',
  webviewNotAllowed: false,
  reason: '',
  channel: '',
};

const initialModalState: ModalState<PaymentMethod> = {
  mode: 'closed',
  data: emptyMethod,
  success: false,
  loading: false,
};

const initialRelationModalState: ModalState<PaymentMethodRelation> = {
  mode: 'closed',
  data: emptyRelation,
  success: false,
  loading: false,
};

const initialState: PaymentMethodTopupState = {
  paymentMethods: [],
  loading: false,
  success: false,
  itemsPerPage: -1,
  // Stores modal state and relevant modal data
  modal: initialModalState,
  relationModal: initialRelationModalState,
  totalCount: 0,
  page: -1,
};

export const paymentMethodTopupSlice = createSlice({
  name: 'paymentMethodTopup',
  initialState,
  reducers: {
    setModalData<
      K extends StateWithModals,
      T extends PaymentMethodTopupState[K] extends ModalState<infer U> ? U : never,
    >(
      state: PaymentMethodTopupState,
      action: {
        payload: {
          modalKey: K;
          data: T;
        };
      },
    ) {
      (state[action.payload.modalKey].data as T) = action.payload.data;
    },
    openModal<K extends StateWithModals, T extends PaymentMethodTopupState[K] extends ModalState<infer U> ? U : never>(
      state: PaymentMethodTopupState,
      action: {
        payload: {
          modalKey: K;
          mode: Exclude<FormModalMode, 'close'>;
          data?: T;
        };
      },
    ) {
      state[action.payload.modalKey].mode = action.payload.mode;
      if (action.payload.data) {
        (state[action.payload.modalKey].data as T) = action.payload.data;
      }
    },
    // Use in case we need to just close the modal and keep modal values
    closeModal(state, action: { payload: { modalKey: StateWithModals } }) {
      state[action.payload.modalKey].mode = 'closed';
    },
    closeAndResetModal(state, action: { payload: { modalKey: StateWithModals } }) {
      const key = action.payload.modalKey;
      (state[key] as any) = initialState[key];
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentMethodTopupByGroup.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethodTopupByGroup.fulfilled, (state, action) => {
      state.paymentMethods = action.payload.paymentMethods;
      state.totalCount = action.payload.totalCount;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
      state.loading = false;
    });
    builder.addCase(getPaymentMethodTopupByGroup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    // Create Payment Method
    builder.addCase(createPaymentMethodTopup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(createPaymentMethodTopup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(createPaymentMethodTopup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
    // Update Payment Method Group
    builder.addCase(updatePaymentMethodTopup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(updatePaymentMethodTopup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(updatePaymentMethodTopup.rejected, state => {
      state.relationModal.loading = false;
      state.relationModal.success = false;
    });

    builder.addCase(getPaymentMethodRelation.pending, state => {
      state.relationModal.loading = true;
      state.relationModal.success = false;
    });
    builder.addCase(getPaymentMethodRelation.fulfilled, (state, action) => {
      state.relationModal.data = {
        ...state.relationModal.data,
        ...action.payload.data,
      };
      state.relationModal.success = true;
      state.relationModal.loading = false;

      if (state.relationModal.data.pspPaymentMethodId) {
        state.relationModal.mode = 'opened-edit';
      }
    });
    builder.addCase(getPaymentMethodRelation.rejected, state => {
      state.relationModal.loading = false;
      state.relationModal.success = false;
    });
    builder.addCase(createPaymentMethodRelation.pending, state => {
      state.relationModal.loading = true;
      state.relationModal.success = false;
    });
    builder.addCase(createPaymentMethodRelation.fulfilled, state => {
      state.relationModal = initialRelationModalState;
    });
    builder.addCase(createPaymentMethodRelation.rejected, state => {
      state.relationModal.loading = false;
      state.relationModal.success = false;
    });
    builder.addCase(updatePaymentMethodRelation.pending, state => {
      state.relationModal.loading = true;
      state.relationModal.success = false;
    });
    builder.addCase(updatePaymentMethodRelation.fulfilled, state => {
      state.relationModal = initialRelationModalState;
    });
    builder.addCase(updatePaymentMethodRelation.rejected, state => {
      state.relationModal.loading = false;
      state.relationModal.success = false;
    });
  },
});

export const { setModalData, openModal, closeAndResetModal, reset } = paymentMethodTopupSlice.actions;
