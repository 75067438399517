import { useContext, useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionStatus, TransactionType } from '@/types';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import {
  type ActionProps,
  TransactionActionsContext,
  TransactionActionType,
} from '@/components/customer/transaction-history/transaction-actions/TransactionActions';
import ActionButton from '@/components/customer/transaction-history/transaction-actions/ActionButton';
import { useParams } from 'react-router-dom';
import { transactionRewardReversal } from '@/lib/redux/slices/transaction-reward-reversal/actions';
import ActionInput from '@/components/customer/transaction-history/transaction-actions/ActionInput';
import { fetchMerchantReversalRewardEmptyResult } from '@/lib/redux/slices/transaction-actions/actions';
import { resetRewardReversal } from '@/lib/redux/slices/transaction-reward-reversal/slice';
import { isAmountAllowed } from '@/utils/helperFunctions';

const RewardReversal: FC<ActionProps> = ({ onClose, transaction, setAccessibility }) => {
  const { selectedTransactionAction } = useContext(TransactionActionsContext);
  const { isMerchantReversalRewardEmpty } = useAppSelector(state => state.transactionActionsSlice);
  const { rewardReversalSuccess, isLoading } = useAppSelector(state => state.transactionRewardReversal);
  const { t } = useTranslation(['customerOverview', 'global']);
  const dispatch = useAppDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const transactionType = transaction?.transactionType ?? '';
  const requestedAmount = transaction?.requestedAmount ?? 0;
  const isReversalRewardAllowed =
    transaction?.transactionType === TransactionType.MerchantReward &&
    transaction.status === TransactionStatus.Approved;
  useEffect(() => {
    if (
      transaction &&
      !selectedTransactionAction &&
      isMerchantReversalRewardEmpty === undefined &&
      customerId &&
      isReversalRewardAllowed
    ) {
      void dispatch(
        fetchMerchantReversalRewardEmptyResult({
          customerId,
          slipId: transaction.slipId ?? '',
        }),
      );
    } else if (!isMerchantReversalRewardEmpty) {
      setAccessibility(false);
    }
  }, [
    selectedTransactionAction,
    customerId,
    dispatch,
    transaction,
    transactionType,
    isReversalRewardAllowed,
    isMerchantReversalRewardEmpty,
    setAccessibility,
  ]);
  useEffect(() => {
    if (rewardReversalSuccess) {
      void dispatch(resetRewardReversal());
      onClose({ success: true });
    }
  }, [rewardReversalSuccess, dispatch, onClose]);
  useEffect(() => {
    if (isMerchantReversalRewardEmpty !== undefined) {
      setAccessibility(isMerchantReversalRewardEmpty);
    }
    return () => {
      setAccessibility(undefined);
    };
  }, [isMerchantReversalRewardEmpty, setAccessibility]);
  const handleConfirm = (amount: number, reason: string): void => {
    if (transaction?.slipId) {
      void dispatch(
        transactionRewardReversal({
          slipId: transaction?.slipId ?? '',
          amount,
          reason,
        }),
      );
    }
  };
  let amount = `${requestedAmount}`;
  if (transaction?.requestedAmount && transaction?.requestedAmountCurrency) {
    amount = `${transaction.requestedAmount} ${transaction.requestedAmountCurrency}`;
  }
  if (selectedTransactionAction === undefined) {
    return (
      <ActionButton
        type={TransactionActionType.RewardReversal}
        hide={!(isMerchantReversalRewardEmpty && isReversalRewardAllowed)}
        label='Reward Reversal'
      />
    );
  }
  if (selectedTransactionAction === TransactionActionType.RewardReversal) {
    return (
      <ActionInput
        disabledButton={isLoading}
        onConfirm={handleConfirm}
        onClose={onClose}
        isAllowed={values => isAmountAllowed(values, requestedAmount)}
        inputLabel={t('widgets.transaction-history.modal-input-amount')}
        hint={t('widgets.transaction-history.information-text-amount', {
          amount,
        })}
      ></ActionInput>
    );
  }
  return null;
};

export default RewardReversal;
