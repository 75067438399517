export interface Channel {
  id: string;
  name: string;
  transactionType: string;
}

export enum CardChannelEnum {
  ATMWithdrawal = 'ATM_WITHDRAWAL',
  POSWithdrawal = 'PRESENT_SPENT',
  VirtualWithdrawal = 'NOT_PRESENT_SPENT',
}
