import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AxiosResponse } from 'axios';

import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';
import type { PaymentMethod, CreateResponse, UpdateResponse, PaymentMethodRelation } from '@/types/PaymentMethod';
import type { RootState } from '../../store';

interface PaymentMethodResponse {
  data: {
    data: PaymentMethod[];
    total: number;
  };
}

interface PaymentMethodRelationResponse {
  data: PaymentMethodRelation;
}

interface GetPaymentMethod {
  countryCode: string;
  groupId: string | null;
  limit: number;
  offset: number;
  includeTotal?: boolean;
  showRelativeBlobUrls?: boolean;
}

export const getPaymentMethodTopupByGroup = createAsyncThunk(
  'paymentMethodTopup.getData',
  async (data: GetPaymentMethod, { rejectWithValue, getState }) => {
    const rootState = getState() as RootState;
    const currentPage = rootState.paymentMethodTopup.page;
    const currentItemsPerPage = rootState.paymentMethodTopup.itemsPerPage;
    let page = -1;

    if (data.offset + 1 === currentPage && currentItemsPerPage > 0 && currentItemsPerPage < data.limit) {
      page = 1;
    }

    try {
      const response = await authAxios.get<PaymentMethodResponse>('/topup/payment-method/paginated', {
        params: {
          country: data.countryCode,
          groupId: data.groupId,
          limit: data.limit,
          offset: data.offset,
          includeTotal: true,
          showRelativeBlobUrls: data.showRelativeBlobUrls ?? true,
        },
      });

      return {
        paymentMethods: response.data.data.data,
        totalCount: response.data.data.total,
        page: page === 1 ? page : data.offset + 1,
        itemsPerPage: data.limit,
      };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const createPaymentMethodTopup = createAsyncThunk(
  'paymentMethodTopup.create',
  async (data: PaymentMethod, { rejectWithValue }) => {
    try {
      const response = await authAxios.post<CreateResponse, AxiosResponse<CreateResponse>, PaymentMethod>(
        '/topup/payment-method',
        data,
      );

      successToast('toast-messages.payment-method-created');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updatePaymentMethodTopup = createAsyncThunk(
  'paymentMethodTopup.update',
  async ({ id, ...data }: PaymentMethod, { rejectWithValue }) => {
    try {
      const response = await authAxios.put<UpdateResponse, AxiosResponse<UpdateResponse>, PaymentMethod>(
        `/topup/payment-method/${id}`,
        data,
      );

      successToast('toast-messages.payment-method-updated');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const getPaymentMethodRelation = createAsyncThunk(
  'paymentMethodTopup.getRelation',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<PaymentMethodRelationResponse>(`/topup/payment-method/relation/${id}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createPaymentMethodRelation = createAsyncThunk(
  'paymentMethodTopup.createRelation',
  async (data: PaymentMethodRelation, { rejectWithValue }) => {
    try {
      const requestBody = {
        ...data,
        channel: 'Online',
      };

      const response = await authAxios.post<PaymentMethodRelationResponse>(
        '/topup/payment-method/relation',
        requestBody,
      );

      successToast('toast-messages.payment-method-relation-created');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updatePaymentMethodRelation = createAsyncThunk(
  'paymentMethodTopup.updateRelation',
  async (data: PaymentMethodRelation, { rejectWithValue }) => {
    try {
      const response = await authAxios.put<PaymentMethodRelationResponse>(
        `/topup/payment-method/relation/${data.kuadyPaymentMethodId}`,
        data,
      );

      successToast('toast-messages.payment-method-relation-updated');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
