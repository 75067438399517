import { useCallback, type FC } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@/components/common/button/Button';
import { openModal, emptyGroup } from '@/lib/redux/slices/payment-method-groups-topup/slice';
import { useAppDispatch, useAppSelector } from '@/lib/redux';

const CreateMethodGroupButton: FC = () => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();
  const countryCode = useAppSelector(({ country }) => country.country.countryCode);

  const handleOpenModal = useCallback(() => {
    dispatch(openModal({ mode: 'opened-create', data: { ...emptyGroup, countryCode } }));
  }, [dispatch, countryCode]);

  return (
    <Button
      textTransform='uppercase'
      onClick={handleOpenModal}
    >
      + {t('payment-method-topup.button-new-method-group')}
    </Button>
  );
};

export default CreateMethodGroupButton;
