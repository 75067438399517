import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainerHeader, GridItem } from './styles';

const AccordionContentHeader: FC = () => {
  const { t } = useTranslation('configurations');

  return (
    <GridContainerHeader>
      <GridItem $cell={[1, 2]} />
      <GridItem $cell={[3, 1]}>{t('limits.per-transaction')}</GridItem>
      <GridItem $cell={[4, 1]}>{t('limits.daily')}</GridItem>
      <GridItem $cell={[5, 1]}>{t('limits.weekly')}</GridItem>
      <GridItem $cell={[6, 1]}>{t('limits.monthly')}</GridItem>
      <GridItem $cell={[7, 1]}>{t('limits.lifetime')}</GridItem>
    </GridContainerHeader>
  );
};

export default AccordionContentHeader;
